import {
  Box,
  IconButton,
  IconButtonProps,
  LinkProps,
  Stack,
  styled,
  useTheme,
} from "@mui/material"
import { DISCORD_URL, TWITTER_URL } from "../constants"
import { ReactComponent as DiscordLogo } from "../assets/icons/discord.svg"
import React from "react"
import { ReactComponent as TwitterLogo } from "../assets/icons/twitter.svg"

const StyledIconButton = styled(({ ...props }: IconButtonProps & LinkProps) => (
  <IconButton {...props} sx={{ ml: 2, p: 0 }}></IconButton>
))(() => ({}))

export const SocialBar = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack display="flex" direction="row">
        <StyledIconButton href={TWITTER_URL} target="_blank">
          <TwitterLogo color={theme.palette.common.white} />
        </StyledIconButton>
        <StyledIconButton href={DISCORD_URL} target="_blank">
          <DiscordLogo color={theme.palette.common.white} />
        </StyledIconButton>
      </Stack>
    </Box>
  )
}
