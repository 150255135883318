import { FARMS_MAP, getMinichefV2Pid } from "../constants/farms"
import { FarmName, FarmType } from "../constants"
import { Zero } from "@ethersproject/constants"
import getStakingContract from "../utils/getStakingContract"
import { useActiveWeb3React } from "."
import { useCallback } from "react"

export enum FarmState {
  Active = "Active",
  Soon = "Soon",
  Paused = "Paused",
}

export function useFarmState(): (
  address: string,
  name: FarmName,
  type: FarmType,
) => Promise<FarmState> {
  const { account, chainId, library } = useActiveWeb3React()

  return useCallback(
    async function fetchFarmState(
      address: string,
      name: FarmName,
      type: FarmType,
    ): Promise<FarmState> {
      if (!chainId || !library || !account) return FarmState.Soon

      const { isEnded } =
        Object.values(FARMS_MAP).find((farm) => farm.name === name) || {}
      const minichefV2 = getStakingContract(type, address, account, library)
      const pid = getMinichefV2Pid(chainId, name)

      if (!minichefV2 || !pid) return FarmState.Soon

      const poolInfo = await minichefV2.poolInfo(pid)
      const allocPoint = poolInfo?.allocPoint || Zero

      // If the farm "hasn't started", "no reward" or "closed" total alloc point should be Zero
      return allocPoint.gt(Zero)
        ? FarmState.Active
        : isEnded
        ? FarmState.Paused
        : FarmState.Soon
    },
    [account, chainId, library],
  )
}
