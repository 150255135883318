import { AppDispatch } from "../state"
import { UniswapPairV2 } from "./../../types/ethers-contracts/UniswapPairV2"
import { updateNoahWethUniPool } from "../state/application"

// Definition of sushi pool reserves (lp)
// ETH is 1, NOAH is 0
export const RESERVE_ETH_INDEX = 1
export const RESERVE_NOAH_INDEX = 0

export default function fetchNoahWethUniPoolInfo(
  dispatch: AppDispatch,
  poolContract: UniswapPairV2 | null,
): void {
  if (!poolContract) {
    dispatch(updateNoahWethUniPool(null))
    return
  }

  void Promise.all([poolContract.totalSupply(), poolContract.getReserves()])
    .then(([totalSupply, reserves]) => {
      poolContract.st
      dispatch(
        updateNoahWethUniPool({
          totalSupply,
          wethReserve: reserves[RESERVE_ETH_INDEX],
          noahReserve: reserves[RESERVE_NOAH_INDEX],
        }),
      )
    })
    .catch((e) => {
      console.error(e)
      dispatch(updateNoahWethUniPool(null))
    })
}
