import { ChainId } from "../constants"

export function getMultichainScanLink(
  chainId: ChainId,
  data: string,
  type: "tx" | "token" | "address" | "block" | "txs" | "erc20_token",
): string {
  let chainScanDomain = "etherscan.io"
  switch (chainId) {
    case ChainId.MAINNET:
      chainScanDomain = "etherscan.io"
      break
    case ChainId.ARBITRUM:
      chainScanDomain = "arbiscan.io"
      break
    case ChainId.PAC:
      chainScanDomain = "pac.0x.studio"
      break
    case ChainId.BLAST_SEPOLIA:
      chainScanDomain = "testnet.blastscan.io"
      break
    case ChainId.BASE_MAINNET:
      chainScanDomain = "basescan.org"
      break
    case ChainId.BASE_SEPOLIA:
      chainScanDomain = "sepolia-explorer.base.org"
      break
    // case ChainId.OPTIMISM:
    //   chainScanDomain = "optimistic.etherscan.io"
    //   break
    default:
      chainScanDomain = "etherscan.io"
  }

  return `https://${chainScanDomain}/${type}/${data}`
}

export function getEtherscanLink(
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  return `https://etherscan.io/${type}/${data}`
}
