import React, { lazy } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

// const Deposit = lazy(() => import("./Deposit"))
const Farm = lazy(() => import("./Farm/Farm"))
const Key = lazy(() => import("./Key/Key"))
// const Pools = lazy(() => import("./Pools"))
// const Risk = lazy(() => import("./Risk"))
// const Swap = lazy(() => import("./Swap"))
// const Withdraw = lazy(() => import("./Withdraw"))
// const Manage = lazy(() => import("./Manage"))

export default function Pages() {
  const isPresale = process.env.REACT_APP_PRESALE_ENABLE === "true"
  const firstPage = isPresale ? "/mintmasterkey" : "/farm"
  return (
    <Switch>
      {/* <Route exact path="/" component={Swap} /> */}
      {/* <Route exact path="/pools" component={Pools} /> */}
      {/* <Route exact path={`/pools/:poolName/deposit`} component={Deposit} /> */}
      {/* <Route exact path={`/pools/:poolName/withdraw`} component={Withdraw} /> */}
      {/* <Redirect from="/pools/:route/:action" to="/pools" /> */}
      {/* <Route exact path="/risk" component={Risk} /> */}
      <Route exact path="/mintmasterkey" component={Key} />
      <Route exact path="/farm" component={Farm} />
      <Redirect from="/" to={firstPage} />
      {/* <Route exact path="/manage" component={Manage} /> */}
    </Switch>
  )
}
