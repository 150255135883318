import { DialogContent, Typography, useTheme } from "@mui/material"
import React, { ReactElement } from "react"
import Dialog from "./Dialog"

interface TermsDialogProps {
  open: boolean
  onClose: () => void
}
export default function TokenClaimDialog({
  open,
  onClose,
}: TermsDialogProps): ReactElement {
  const theme = useTheme()

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={onClose}
      maxWidth="lg"
      data-testid="termsAndConditions"
    >
      <DialogContent sx={{ whiteSpace: "pre-line", px: 6, pb: 6, pt: 3 }}>
        <Typography textAlign="left" color="primary" variant="h4" mb={3}>
          TERMS & CONDITIONS
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS BELOW`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`YOU MUST BE AND HEREBY AFFIRM THAT YOU ARE AN ADULT OF THE LEGAL AGE OF MAJORITY IN YOUR COUNTRY OR STATE OF RESIDENCE.  If you are under the legal age of majority, your parent or legal guardian must consent to this agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PARTICIPATING.  IF YOU DO NOT AGREE WITH ALL OF THE TERMS OF THIS AGREEMENT, YOU MAY NOT PARTICIPATE IN THE GAME.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`PRODUCT LICENSE AND TERMS OF SERVICE (the "Agreement")`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`You agree that your use of our website, including any updates, shall be subject to the terms of this Agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Our smart contracts, games, products, services and/or other domains or websites operated by us (collectively, the "Services" or “Game”) are provided “as is” and “as available” without warranty of any kind. We reserve the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice and for any reason, in our sole discretion.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`1. ELIGIBILITY`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`In order to participate in the Game, you may be required to:`}
          <li>meet minimum age requirements required by law</li>
          <li>agree to this Agreement</li>
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`2. ACCESS AND COSTS`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`You acknowledge and agree that you will provide at your own cost and expense the equipment, Internet or other connection charges, required to access and use the Game. We make no warranty that the Game can be accessed on all personal computers, smartphones, tablets or other devices (each, a "Device", or in the plural, "Devices"), by means of any specific Internet or other connection provider, or in all countries.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`YOU ARE SOLELY RESPONSIBLE FOR ANY THIRD PARTY COSTS YOU INCUR TO USE THE GAME.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`3. LICENSE`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`A. We grant you a personal, limited, non-exclusive license to use the Services for your non-commercial use. To the fullest extent permitted by applicable law, this license granted to use the Game is non-transferable. You may not rent, lease, lend, sell, redistribute or sublicense the Game. You may not copy (except as expressly permitted by this license and any other applicable terms, conditions, or usage rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Game, any updates, or any part thereof (except as and only to the extent allowed by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the licensed application).`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`B. The license rights granted to you herein are also subject to the limitations set forth below.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Any use of the Services in violation of these limitations is a serious violation of the Agreement, subjects you to immediate termination of your license you agree that you will not, under any circumstances:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`i. use, develop, host or distribute cheats, automation software (bots), modded lobbies, hacks, mods or any other unauthorized third-party software in connection with the Product, or engage in any form of cheating, boosting, or booting;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`ii. exploit or reverse engineer the Product (or any of its parts), for any commercial purpose, including without limitation`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`iii. use any unauthorized third-party software that intercepts, "mines", or otherwise collects information from or through the Product;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`iv. except our written consent, host, provide or develop matchmaking services for the Product, or intercept, emulate or redirect the communication protocols used by us in any way, for any purpose, including without limitation unauthorized play over the internet, network play, or as part of content aggregation networks;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`v. facilitate, create or maintain any unauthorized connection to the Game, including without limitation (a) any connection to any unauthorized server that emulates, or attempts to emulate, the Game; and (b) any connection using programs or tools not expressly approved by us in writing;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`vi. violate any applicable law or regulation in connection with your use of the Game;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`vii. disrupt or assist in the disruption of: (i) any computer, device or server used to support the Game; or (ii) any other player's use of a Game;`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`viii. interfere or attempt to interfere with the operation of the Game in any way through any means or device including, but not limited to, launching a denial of service attack, spamming, hacking, or uploading computer viruses or time bombs; or`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`ix. reproduce, distribute, display, transfer or use any part of the Game except as expressly authorized by us.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`4. BINDING ARBITRATION AND CLASS ACTION WAIVER`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING WAIVING YOUR RIGHT TO FILE A LAWSUIT IN COURT OR TO PURSUE CLAIMS IN A CLASS OR REPRESENTATIVE CAPACITY.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`These BINDING ARBITRATION AND CLASS ACTION WAIVER provisions apply to you if you are domiciled in and/or acquired and use the Game in the United States.  In the United States, this Agreement is governed by the Federal Arbitration Act (“FAA”) and federal arbitration law.  These provisions may also apply to you if you are domiciled in and/or acquired and use the Product from outside the United States. See JURISDICTION AND APPLICABLE LAW below for details.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`To the fullest extent allowed by applicable law, you agree to submit all Disputes between us, if any, to individual, binding arbitration pursuant to the provisions in this Section 4.  A “Dispute” means any dispute, claim, or controversy (except those specifically exempted below) between you and us that in any way relates to or arises from any aspect of our relationship, including, without limitation, your use or attempted use of the Product, all marketing related to the Product, all Services, Service Provided Content, and Virtual Currency, any licensed content, and all matters relating to or arising from this Agreement (including [We or Us]’s Privacy Policy and all other terms incorporated into this Agreement) or any other agreement between you and [We or Us], including any disputes over the validity or enforceability of this agreement to arbitrate.  A Dispute shall be subject to these BINDING ARBITRATION AND CLASS ACTION WAIVER provisions regardless of whether it is based in contract, statute, regulation, ordinance, tort (including fraud, misrepresentation, fraudulent inducement, or negligence), or any other legal or equitable theory.  This includes claims or requests for relief that accrued before you entered into this Agreement.  You understand that there is no judge or jury in arbitration and that court review of an arbitration award is limited.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Binding Arbitration: If a Dispute cannot be resolved through negotiations, then either party may initiate binding arbitration as the sole means to formally resolve the Dispute, unless an exception applies as stated below.  Except in the event of a Mass Arbitration (as defined below), the arbitration will be administered by JAMS in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “JAMS Rules”) effective as of the date of the Notice of Dispute, which are available at the JAMS website, http://www.jamsadr.com/rules-streamlined-arbitration, as modified by this Agreement.  If, for any reason, JAMS is unable to provide the arbitration, then except as otherwise stated below, you may file your Dispute with any national arbitration company that handles consumer arbitrations following procedures that are substantially similar to the JAMS Rules.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Arbitration hearings may be conducted by videoconference unless the arbitrator believes an in-person hearing is necessary.  In such instances, the location of an arbitration hearing will be decided pursuant to the JAMS Rules. For residents outside the United States, arbitration shall be initiated in Los Angeles County, California, and you and [We or Us] agree to submit to the personal jurisdiction of any federal or state court in Los Angeles County, California, in order to compel arbitration, to stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`The arbitrator will make a decision in writing but need not provide a statement of reasons unless requested by a party. The arbitrator must follow applicable law. The decision of the arbitrator shall be final and binding on you and us, and any award of the arbitrator may be entered in any court of competent jurisdiction.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`The arbitrator shall determine the scope and enforceability of this arbitration agreement, including whether a Dispute is subject to arbitration. The arbitrator has authority to decide all issues of validity, enforceability or arbitrability, including, but not limited to, where a party raises as a defense to arbitration that the claims in question are exempted from the arbitration requirement or that any portion of this agreement is not enforceable.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`If a lawsuit filed in court includes claims or requests for relief that are arbitrable and claims or requests for relief that are not, you and us agree that any non-arbitrable claims or requests for relief shall be stayed pending the completion of the arbitration of the arbitrable claims or requests for relief.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Class Action Waiver:  TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, YOU AND WE AGREE THAT EACH PARTY MAY BRING DISPUTES AGAINST THE OTHER PARTY ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A CLASS ACTION, COLLECTIVE ACTION OR CLASS ARBITRATION, OR AS A PRIVATE ATTORNEY GENERAL.  To the extent applicable law does not permit waiver of private attorney general claims, but permits them to be arbitrated, then such claims shall be resolved in arbitration.  The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`If any provision in Section 4 of this Agreement is found to be unenforceable, that provision shall be severed with the remainder of this Agreement remaining in full force and effect. The foregoing shall not apply to the prohibition against class or collective actions as provided for above. This means that if the prohibition against class or collective actions is found to be unenforceable for any reason, the entire Section 4 of this Agreement (but only Section 4) shall be null and void.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Exception - Litigation of Intellectual Property and Small Claims Court Claims: Notwithstanding the parties' decision to resolve all disputes through arbitration, either party may bring an action in state or federal court that only asserts claims for patent infringement or invalidity, copyright infringement, moral rights violations, trademark infringement, and/or trade secret misappropriation, but not, for clarity, claims related to the license granted to you for the Product under this Agreement. Such claims are subject to the  jurisdiction and applicable law provisions in Section 5.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Either party may also seek relief in a small claims court for any individual disputes or claims within the scope of that court's jurisdiction. If an arbitration is filed, before the arbitrator is formally appointed either party can send written notice to the opposing party and the applicable arbitration provider that it wants the case decided by a small claims court, after which the arbitration provider may close the case.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Exception – Mass Arbitration Before FedArb: Notwithstanding the parties’ decision to have arbitrations administered by JAMS, if 20 or more demands for arbitration are filed relating to the same or similar subject matter and sharing common issues of law or fact, and counsel for the parties submitting the demands are the same or coordinated, you and we agree that this will constitute a “Mass Arbitration.” If a Mass Arbitration is commenced, you and we agree that it shall not be governed by JAMS Rules or administered by JAMS. Instead, a Mass Arbitration shall be administered by FedArb, a nationally recognized arbitration provider, and governed by the FedArb Rules in effect when the Mass Arbitration is filed, excluding any rules that permit arbitration on a class-wide basis (the “FedArb Rules”), and under the rules set forth in this Agreement. The FedArb Rules are available at https://www.fedarb.com/ or by calling 1-650-328-9500. You and we agree that the Mass Arbitration shall be resolved using FedArb’s Framework for Mass Arbitration Proceedings ADR-MDL, available at https://www.fedarb.com/. Before any Mass Arbitration is filed with FedArb, you and we agree to contact FedArb jointly to advise that the parties intend to use FedArb’s Framework for Mass Arbitration Proceedings ADR-MDL. The individual demands comprising the Mass Arbitration shall be submitted on FedArb’s claim form(s) and as directed by FedArb. You and We agree that if either party fails or refuses to commence the Mass Arbitration before FedArb rather than JAMS, you or We may seek an order from JAMS compelling compliance and directing administration of the Mass Arbitration before FedArb. Pending resolution of any such requests, you and we agree that all arbitrations comprising the Mass Arbitration (and any obligation to pay arbitration fees) shall be stayed.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`5. JURISDICTION, AND APPLICABLE LAW AND CONTRACTING PARTIES`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`The Game is made available subject to the terms of this Agreement. If you use the Game from:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`A.   For residents in the United States, Mexico or Canada, then any claims or requests for relief arising out of this Agreement (including interpretation, claims for breach, and all other claims or requests for relief (including consumer protection, unfair competition, and tort claims)) will be subject to the laws of the State of Delaware, without reference to conflict of laws principles. If any court or arbitrator determines that the "Class Action Waiver" paragraph set forth above is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then any and all claims arising out of this Agreement (including interpretation, claims for breach, and all other claims or requests for relief (including consumer protection, unfair competition, and tort claims)) shall be decided under the laws of the state where you were a citizen at the time you obtained or bought the Product that was subject to this Agreement. In addition, you and we irrevocably consent to the exclusive jurisdiction and venue of state or federal courts in Los Angeles County, California to resolve any claims or requests for relief that are subject to exceptions to the arbitration agreement described in BINDING ARBITRATION AND CLASS ACTION WAIVER above, or otherwise determined not to be arbitrable.  Nothing in this paragraph shall preclude you or us from removing to federal court a case originally filed in state court, if federal court jurisdiction exists.  To the fullest extent permitted by law, any claim or request for relief in a demand for arbitration filed pursuant to Section 4 of this Agreement, as well as any claim or request for relief in a lawsuit filed in court under an exception to the arbitration agreement in Section 4, shall be barred if filed more than two (2) years after the date that the claim or request for relief accrued.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`B.   For residents in the European Union and the United Kingdom, then the laws of England and Wales govern the interpretation of this Agreement and apply to claims for breach of it, without reference to conflict of laws principles. All other claims, including claims regarding consumer protection laws, unfair competition laws, and in tort, will be subject to the laws of the European Union country (e.g., the French Republic, or the Federal Republic of Germany) or the United Kingdom in which you acquired and use the Product. In addition, with respect to jurisdiction, you may choose either the courts of the country (e.g., the United Kingdom, the French Republic, or the Federal Republic of Germany) in which you acquired and use the Product, or in the alternative the courts of England and Wales or other court as applicable under the Brussels Regulation EC 44/2001.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`C.   For residents in Australia or Japan, then the laws of Australia govern the interpretation of this Agreement and apply to claims for breach of it, without reference to conflict of laws principles. All other claims, including claims regarding consumer protection laws, unfair competition laws, and in tort, will be subject to the laws of the country in which you acquired and use the Product (being either Australia or Japan). To the extent permitted by applicable law, you agree to the jurisdiction of the courts of New South Wales, Australia.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`D.  For residents in the Rest of the World, if you acquired and use this Game from countries other than those listed in sections A, B and C above, then you do so on your own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable, and you expressly indemnify and hold harmless us from any and all claims, loss, injury, damage, or costs arising from your use of the Product to the extent permitted by applicable law. No warranty or representation is made by us that the Product or any use of the Product outside of the countries listed in sections A, B and C above complies with any applicable local law. Further your use of the Product and all claims arising out of or related to the Product or this Agreement will, to the extent permitted under applicable law, be subject to the laws of England and Wales, without reference to conflict of laws principles and you consent to the jurisdiction of the courts of England and Wales.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`To the extent permitted by applicable law, you and we agree to waive rights to a trial by jury.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`6. Fees and Payments`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`(a) If you elect to participate in the game, any financial transactions that you engage in will be conducted solely through the Ethereum network. We will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. We will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage or any other transactions that you conduct via the Ethereum network.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`(b) Ethereum requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Ethereum network. The Gas Fee funds the network of computers that run the decentralized Ethereum network. This means that you will need to pay a Gas Fee for each transaction.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`7. Game of Skills`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`When you play the game, you are utilizing skill to make decisions. In other words, your abilities determine the outcome of the skills-based competitions. Games of skill are permitted in most jurisdictions and do not constitute gambling (which generally requires a game of chance, prize, and consideration to play). Some jurisdictions limit games of skill where payments are involved. It is your responsibility to determine whether the jurisdiction in which you are located permits skill-based competitions.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`8. Privacy`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`When you play the game, the only information we collect from you is your blockchain wallet address, completed transaction hashes, and token identifiers. We do not collect any personal information from you. We also use third-party services like Google Analytics, which may receive your publicly available personal information. We do not take responsibility for any information you make public on the Ethereum blockchain by taking actions through the front-end interface.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`9. TERMINATION.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`The license granted under this Agreement is effective until terminated by you or us. Upon termination of the license, You shall cease all use of the Game.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`A.    FOR RESIDENTS OUTSIDE THE EUROPEAN UNION AND THE UNITED KINGDOM:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Your rights under this Agreement will terminate automatically without notice from us if you fail to comply with any term(s) of this Agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We reserve the right to terminate this Agreement without cause on immediate written notice.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Nothing herein limits our rights to suspend, terminate or delete any account.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`WE MAY SUSPEND, TERMINATE, MODIFY, OR DELETE ANY ACCOUNT AT ANY TIME FOR ANY REASON OR FOR NO REASON, WITH OR WITHOUT NOTICE TO YOU, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. For purposes of explanation and not limitation, many account suspensions, terminations and/or deletions may be the result of violations of this Agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`B.     FOR RESIDENTS IN THE EUROPEAN UNION AND UNITED KINGDOM:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`WE MAY TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS AGREEMENT AND/OR SUSPEND, MODIFY, OR DELETE YOUR ACCOUNT AT ANY TIME WITHOUT GIVING YOU ANY PRIOR NOTICE IF YOU SERIOUSLY VIOLATE THIS AGREEMENT.  SERIOUS VIOLATIONS ARE VIOLATIONS OF IMPORTANT PROVISIONS WHICH INCLUDE SECTIONS 1, 2  AND 3 OF THIS AGREEMENT OR REPEATED VIOLATIONS OF OTHER PROVISIONS OF THIS AGREEMENT.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`If you are resident in Germany, the terms in Appendix 1 will apply to you instead of Section B(i).`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`WE MAY TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS AGREEMENT AND/OR SUSPEND, MODIFY, OR DELETE YOUR ACCOUNT IF IT HAS ANOTHER VALID REASON TO DO SO (FOR EXAMPLE, CEASING AN ONLINE SERVICE FOR ECONOMIC REASONS DUE TO A LIMITED NUMBER OF USERS CONTINUING TO MAKE USE OF THE SERVICE OVER TIME) OR WITHOUT REASON BY GIVING YOU REASONABLE PRIOR NOTICE.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`IF WE TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS AGREEMENT AND/OR SUSPENDS OR DELETES YOUR ACCOUNT, THIS MEANS THAT YOUR ACCESS AND RIGHT TO USE THE PRODUCT(S) AND SERVICE PROVIDED CONTENT WILL BE REVOKED.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`10. WARRANTY DISCLAIMER AND LIMITATION OF LIABILITY`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`A.    WARRANTY DISCLAIMER.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE GAME IS PROVIDED "AS IS" AND WE DO NOT WARRANT THAT THE GAME WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE GAME IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ALL OF THE GAME CONTENT WILL BE ACCURATE. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR USE, AND NON-INFRINGEMENT.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`B.    LIMITATION OF LIABILITY.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`i.    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND WITHOUT PREJUDICE TO STATUTORY CONFORMITY WARRANTIES THAT MAY APPLY TO YOU UNDER YOUR LOCAL LAWS, NEITHER WE NOR OUR PARENT, SUBSIDIARIES, LICENSORS OR AFFILIATES SHALL BE LIABLE IN ANY WAY FOR DAMAGE OR LOSS OF ANY KIND RESULTING FROM (A) THE USE OF OR INABILITY TO USE THE PRODUCT INCLUDING WITHOUT LIMITATION LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION; (B) THE LOSS OR DAMAGE TO CHARACTERS, ACCOUNTS, STATISTICS, INVENTORIES, VIRTUAL GOODS, OR USER PROFILE INFORMATION; OR (C) INTERRUPTIONS OF SERVICE INCLUDING WITHOUT LIMITATION ISP DISRUPTIONS, SOFTWARE OR HARDWARE FAILURES, FAILURES OF ANY THIRD PARTY BILLING SOLUTIONS OR OTHER SERVICES, OR ANY OTHER EVENT WHICH MAY RESULT IN A LOSS OF DATA OR DISRUPTION OF SERVICE. IN NO EVENT WILL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, REMOTE, SPECULATIVE, PUNITIVE OR CONSEQUENTIAL DAMAGES.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`ii.    IMPORTANT INFORMATION RELATING TO WARRANTY DISCLAIMER AND LIMITATION OF LIABILITY: BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN WARRANTIES OR THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL, INCIDENTAL, OR OTHER TYPES OF DAMAGES, IN SUCH STATES OR JURISDICTIONS, YOU MAY HAVE DIFFERENT OR ADDITIONAL RIGHTS ACCORDING TO THE APPLICABLE LAWS OF COUNTRY FROM WHICH YOU VALIDLY ACQUIRED AND USE THE PRODUCT AND IN WHICH CASE THE LIABILITY AND OUR AFFILIATES SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`iii.    Member States of the European Union: subject to Section 5, We shall be liable in accordance with statutory law in cases of its (a) intentional misconduct; (b) gross negligence; (c) breach of applicable Product Liability Acts. Without limiting the foregoing, We may only be liable for modest levels of negligence in cases of a breach of a "material" contractual obligation under the Agreement, the breach of which would jeopardize the purpose of the Agreement. In such circumstances, Our liability will be limited to typical and foreseeable damages: in other circumstances We shall not be liable for acts of modest negligence.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`11. INDEMNIFICATION`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`You agree to indemnify, defend and hold harmless us and our affiliates, and their respective officers, directors, owners, agents, employees, contractors, information providers and licensors ("Indemnified Party", and collectively the "Indemnified Parties") from and against any claims, liability, losses, costs and expenses (including attorneys' fees) incurred by an Indemnified Party in connection with any breach by you of the Agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We reserve the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with our defense of such claim.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`12. AVAILABILITY`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Except as otherwise set forth herein and/or as otherwise required by applicable law, We do not guarantee that any particular Service will be available at all times or at any given time or that We will continue to offer the Service for any particular length of time.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`FOR RESIDENTS OUTSIDE THE EUROPEAN ECONOMIC AREA AND THE UNITED KINGDOM:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We may change and update the Service without notice to you. We make no warranty or representation regarding the availability of online Game features and reserves the right to modify or discontinue online Game features in its discretion without notice, including for example, ceasing an online service for economic reasons due to a limited number of users continuing to make use of the online service over time.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`To the fullest extent permitted by applicable law (i) We, and our licensors, reserve the right to change, suspend, remove, or disable access to any Service at any time without notice, and (ii) We will not be liable for the removal of or disabling of access to any Product.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`FOR RESIDENTS IN THE EUROPEAN ECONOMIC AREA AND THE UNITED KINGDOM:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We and our licensors may change the Service at any time in order to, for example: (A) ensure compliance with applicable laws and/or reflect change in relevant laws and regulatory requirements; (B) perform temporary maintenance, fix bugs, implement technical adjustments, and make improvements, (C) update or upgrade the Product including updating the structure, design, or layout of the Game; (D) ensure the security of the Game; and (E) to combat against illegal and/or harmful activities and the use of unauthorized programs or other activities which breach this Agreement. We will not be liable for any malfunction or error to the Service caused by your failure to install an update where we have informed you of the consequences of not installing the update.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We make no warranty or representation regarding the availability of online Game features and reserves the right to modify or discontinue online Product features in its discretion with reasonable notice, including for example, ceasing an online service for economic reasons due to a limited number of users continuing to make use of the online service over time.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`13. Assumption of Risk`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`You accepts and acknowledges all risks associated with the following:`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`(a) You are solely responsible for determining what, if any, taxes apply to any purchase, sale, or transfer of game item or digital assets.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`(b) Any transfer of cryptocurrency assets occurs within public blockchain that is not controlled by us. Such transfer may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Some transactions shall be deemed to be made when recorded on a public blockchain ledger, which is not necessarily the date or time that you initiated the transaction.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`(c) There are risks associated with using an Internet based digital assets, including but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your Wallet. We will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience during this game`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`14. GENERAL`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`A.    Severability`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`Except as otherwise stated above, if any provision of this Agreement shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`B.    Survival`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`SECTIONS 5, 8, 10, 11 and, those other sections that by their terms apply after this Agreement ends, will survive any termination or cancellation of this Agreement.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`C.    Assignment and Transfer`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We may assign this Agreement, in whole or in part, at any time without notice to you. You may not assign this agreement or transfer any rights to use the services.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`D.    Force Majeure`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`We shall not be liable for any delay or failure to perform resulting from causes outside our reasonable control, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`E.    Equitable Remedies`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`In the event that you breach this Agreement, you hereby agree that We would be irreparably damaged if this Agreement were not specifically enforced, and therefore you agree that We shall be entitled, without bond, other security, or proof of damages, to appropriate equitable remedies with respect to breaches of this Agreement, in addition to such other remedies as We may otherwise have available to it under applicable laws.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`F.    Entire Agreement`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`This Agreement constitutes the entire agreement and understanding between you and [We or Us], and supersedes any prior or contemporaneous agreements or understandings, whether written or oral, relating to the matters contained herein.`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 600,
          }}
        >
          {`Appendix 1`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`SUPPLEMENTAL TERMS – APPLICABLE TO RESIDENTS OF GERMANY`}
        </Typography>
        <Typography
          variant="subtitle1"
          textTransform="none"
          marginBottom={2}
          sx={{
            color: theme.palette.text.secondary,
          }}
        >
          {`WE MAY TERMINATE THE LICENSE GRANTED TO YOU UNDER THIS AGREEMENT AND/OR SUSPEND, MODIFY, OR DELETE YOUR ACCOUNT IF YOU SERIOUSLY VIOLATE THIS AGREEMENT. WE WILL NOTIFY YOU IN ADVANCE IF WE DECIDE TO TERMINATE OR SUSPEND THE LICENSE, UNLESS WE ARE TERMINATING OR SUSPENDING IT TO COMPLY WITH A LEGAL OBLIGATION WHICH DOES NOT INCLUDE A PRIOR NOTICE OBLIGATION. WE WILL RESTORE THIS LICENSE IF YOU HAVE GOOD CAUSE TO BELIEVE OUR ACTIONS WERE NOT SUFFICIENTLY JUSTIFIED. SERIOUS VIOLATIONS ARE VIOLATIONS OF IMPORTANT PROVISIONS WHICH INCLUDE SECTION 1, 2 AND 3 OF THIS AGREEMENT, REPEATED VIOLATIONS OF OTHER PROVISIONS OF THIS AGREEMENT (INCLUDING FURTHER NON-COMPLIANCE WHERE YOU HAVE ALREADY RECEIVED A PRIOR WARNING).`}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
