import { AddressZero, Zero } from "@ethersproject/constants"
import {
  BN_DAY_IN_SECONDS,
  ChainId,
  FarmType,
  MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
} from "../constants"
import {
  BasicFarmInfo,
  FARMS_MAP,
  getFarmsWithPids,
  getMinichefV2Pid,
} from "../constants/farms"
import {
  MinichefV2ExternalTokenReward,
  MinichefV2PendingToken,
  MinichefV2PoolData,
  MinichefV2PoolsData,
  MinichefV2RewardersData,
  MinichefV2UserRewards,
} from "../interfaces/minichefV2"
import { createMultiCallContract, getMulticallProvider } from "../utils"
import { Contract } from "ethcall"
import ERC20_GENERIC_TOKEN_ABI from "../constants/abis/genericTokenErc20.json"
import { GenericTokenErc20 } from "../../types/ethers-contracts/GenericTokenErc20"
import MINICHEF_V2_ERC20_CONTRACT_ABI from "../constants/abis/minichefV2Erc20.json"
import { MinichefV2Erc20 } from "../../types/ethers-contracts/MinichefV2Erc20"
import { MulticallContract } from "../types/ethcall"
import SIMPLE_REWARDER_CONTRACT_ABI from "../constants/abis/simpleRewarder.json"
import { SimpleRewarder } from "../../types/ethers-contracts/SimpleRewarder"
import { Web3Provider } from "@ethersproject/providers"

function getFarmsWithBasicInfos(chainId: ChainId) {
  return Object.values(FARMS_MAP || {})
    .filter(({ typeAsset }) => typeAsset === FarmType.ERC20)
    .map(
      ({ addresses, name, token }) =>
        ({
          name: name,
          address: addresses[chainId],
          rewardToken: token.addresses[chainId],
        } as BasicFarmInfo),
    )
}

export async function getMinichefV2Erc20PoolsData(
  library: Web3Provider,
  chainId: ChainId,
): Promise<MinichefV2PoolsData | null> {
  const farmsData = getFarmsWithBasicInfos(chainId)
  const ethCallProvider = await getMulticallProvider(library, chainId)
  const minichefAddress = MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES[chainId]
  const farmsWithPids = getFarmsWithPids(chainId, farmsData)

  if (!ethCallProvider || !minichefAddress || !farmsWithPids.length) return null

  try {
    const erc20GenericTokenMulticallContracts = farmsData
      .filter(({ rewardToken }) => rewardToken && rewardToken !== AddressZero)
      .map((farm) =>
        createMultiCallContract<GenericTokenErc20>(
          farm.rewardToken,
          ERC20_GENERIC_TOKEN_ABI,
        ),
      )

    // Fetch amount staked for each pool
    const poolBalanceOfPromise = ethCallProvider.all(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      erc20GenericTokenMulticallContracts.map((erc20GenericTokenContract) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        erc20GenericTokenContract.balanceOf(minichefAddress),
      ),
    )

    const [poolBalance] = await Promise.all([poolBalanceOfPromise])

    const minichefV2Erc20Contract = new Contract(
      minichefAddress,
      MINICHEF_V2_ERC20_CONTRACT_ABI,
    ) as MulticallContract<MinichefV2Erc20>

    // Fetch NOAH distribution amounts
    const [noahPerSecond, totalAllocPoint] = await ethCallProvider.tryEach(
      [
        minichefV2Erc20Contract.noahPerSecond(),
        minichefV2Erc20Contract.totalAllocPoint(),
      ],
      [false, false],
    )

    // Fetch info for each pool
    const poolInfos = await ethCallProvider.tryAll(
      farmsWithPids.map((farm) =>
        minichefV2Erc20Contract.poolInfo(
          getMinichefV2Pid(chainId, farm.farmName) || 0,
        ),
      ),
    )

    // Fetch rewarder data
    const rewardersData = await getMinichefV2Erc20RewardersData(
      library,
      chainId,
    )

    // Aggregate pools data
    const poolsData = farmsWithPids.reduce((poolsAcc, { farmName, pid }, i) => {
      const poolInfo = poolInfos[i]
      if (poolInfo) {
        const noahPerDay = noahPerSecond
          .mul(BN_DAY_IN_SECONDS)
          .mul(poolInfo.allocPoint)
          .div(totalAllocPoint)
        return {
          [farmName]: {
            noahPerDay,
            pid: getMinichefV2Pid(chainId, farmName),
            totalAmount: poolBalance[i],
            externalReward: rewardersData?.[pid] || {},
          } as MinichefV2PoolData,
          ...poolsAcc,
        }
      }
      return poolsAcc
    }, {} as { [name: string]: MinichefV2PoolData })

    return poolsData
  } catch (e) {
    const error = e as Error
    error.message = `Failed to get pools minichef v2 erc-20 pool data; ${error.message}`
    console.error(error)
    return null
  }
}

export async function getMinichefV2Erc20UsersData(
  library: Web3Provider,
  chainId: ChainId,
  account?: string,
): Promise<MinichefV2UserRewards | null> {
  const farmsData = getFarmsWithBasicInfos(chainId)
  const ethCallProvider = await getMulticallProvider(library, chainId)
  const minichefAddress = MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES[chainId]
  const farmsWithPids = getFarmsWithPids(chainId, farmsData)

  if (!minichefAddress || !ethCallProvider || !farmsWithPids.length || !account)
    return null

  try {
    const minichefV2Erc20Contract = new Contract(
      minichefAddress,
      MINICHEF_V2_ERC20_CONTRACT_ABI,
    ) as MulticallContract<MinichefV2Erc20>

    // Fetch amount of lpToken staked, and NOAH reward debt to user
    const pendingNoahAmountsPromise = ethCallProvider.all(
      farmsWithPids.map(({ pid }) =>
        minichefV2Erc20Contract.pendingNoah(pid, account),
      ),
    )
    const depositAmountsPromise = ethCallProvider.all(
      farmsWithPids.map(({ pid }) =>
        minichefV2Erc20Contract.userInfo(pid, account),
      ),
    )

    // Fetch addresses of rewarder contracts for third-party rewards
    const rewarderAddressesPromise = ethCallProvider.all(
      farmsWithPids.map(({ pid }) => minichefV2Erc20Contract.rewarder(pid)),
    )
    const [depositAmounts, pendingNoahAmounts, rewarderAddresses] =
      await Promise.all([
        depositAmountsPromise,
        pendingNoahAmountsPromise,
        rewarderAddressesPromise,
      ])

    const rewarderContractsMap = {} as {
      [pid: number]: MulticallContract<SimpleRewarder>
    }

    rewarderAddresses.forEach((address, index) => {
      if (address !== AddressZero) {
        rewarderContractsMap[farmsWithPids[index].pid] =
          createMultiCallContract<SimpleRewarder>(
            address,
            SIMPLE_REWARDER_CONTRACT_ABI,
          )
      }
    })

    const rewarderPids = Object.keys(rewarderContractsMap)
    const pendingTokensAddress = await ethCallProvider.all(
      rewarderPids.map((pid) => rewarderContractsMap[pid].rewardToken()),
    )

    // Fetch pending rewards for third-party rewards
    const pendingTokensReward = await ethCallProvider.all(
      rewarderPids.map((pid) =>
        rewarderContractsMap[pid].pendingToken(account),
      ),
    )

    const pidToPendingTokensMap = rewarderPids.reduce(
      (pidTokensAcc, pid, index) => {
        return {
          ...pidTokensAcc,
          [pid]: {
            pendingTokenAddress: pendingTokensAddress[index] || AddressZero,
            pendingTokenReward: pendingTokensReward[index] || Zero,
          } as MinichefV2PendingToken,
        }
      },
      {} as { [pid: number]: MinichefV2PendingToken },
    )

    // Aggregate user rewards data
    return farmsWithPids.reduce((poolsAcc, { pid, farmName }, index) => {
      const amountStaked = depositAmounts[index].amount
      const pendingNoah = pendingNoahAmounts[index]
      const pendingToken = {
        rewardToken: pidToPendingTokensMap[pid]?.pendingTokenAddress,
        rewardAmount: pidToPendingTokensMap[pid]?.pendingTokenReward,
      } as MinichefV2ExternalTokenReward
      return {
        ...poolsAcc,
        [farmName]: {
          amountStaked: amountStaked || Zero,
          pendingNoah: pendingNoah || Zero,
          pendingToken: pendingToken,
        },
      } as MinichefV2UserRewards
    }, {} as MinichefV2UserRewards)
  } catch (e) {
    const error = e as Error
    error.message = `Failed to get user minichef v2 erc-20 user data; ${error.message}`
    console.error(error)
    return null
  }
}

export async function getMinichefV2Erc20RewardersData(
  library: Web3Provider,
  chainId: ChainId,
): Promise<MinichefV2RewardersData | null> {
  const farmsData = getFarmsWithBasicInfos(chainId)
  const ethCallProvider = await getMulticallProvider(library, chainId)
  const minichefAddress = MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES[chainId]
  const farmsWithPids = getFarmsWithPids(chainId, farmsData)

  if (!ethCallProvider || !minichefAddress || !farmsWithPids.length) return null

  try {
    const minichefV2Erc20Contract = new Contract(
      minichefAddress,
      MINICHEF_V2_ERC20_CONTRACT_ABI,
    ) as MulticallContract<MinichefV2Erc20>

    // Fetch rewarders address
    const rewarderAddresses = await ethCallProvider.tryAll(
      farmsWithPids.map(({ pid }) => minichefV2Erc20Contract.rewarder(pid)),
    )

    const rewarderContractsMap = {} as {
      [pid: number]: MulticallContract<SimpleRewarder>
    }

    rewarderAddresses.forEach((address, index) => {
      if (address && address !== AddressZero) {
        rewarderContractsMap[farmsWithPids[index].pid] =
          createMultiCallContract<SimpleRewarder>(
            address,
            SIMPLE_REWARDER_CONTRACT_ABI,
          )
      }
    })

    const rewarderPids = Object.keys(rewarderContractsMap)
    const rewarderTokensPromise = await ethCallProvider.tryEach(
      rewarderPids.map((pid) => rewarderContractsMap[pid].rewardToken()),
      Array(rewarderPids.length).fill(true),
    )
    const rewarderAmountPerSecondsPromise = ethCallProvider.tryEach(
      rewarderPids.map((pid) => rewarderContractsMap[pid].rewardPerSecond()),
      Array(rewarderPids.length).fill(true),
    )
    // Fetch reward token & amount per sec
    const [rewarderTokens, rewarderAmountPerSeconds] = await Promise.all([
      rewarderTokensPromise,
      rewarderAmountPerSecondsPromise,
    ])

    // Aggregate rewarder data
    const poolsRewarderData = rewarderPids.reduce((poolsRewardAcc, pid, i) => {
      const rewarderContract = rewarderContractsMap[pid]
      const rewardTokenAddress = rewarderTokens[i]?.toLowerCase()
      const rewardPerSecond = rewarderAmountPerSeconds[i]
      const rewardPerDay = rewardPerSecond?.mul(BN_DAY_IN_SECONDS)
      if (!rewardTokenAddress || !rewardPerSecond || !rewardPerDay)
        return poolsRewardAcc
      return {
        ...poolsRewardAcc,
        [pid]: {
          rewardTokenAddress,
          rewardPerDay,
          rewardPerSecond,
          rewarderAddress: rewarderContract.address.toLowerCase(),
        },
      }
    }, {} as MinichefV2RewardersData)
    return poolsRewarderData
  } catch (e) {
    const error = e as Error
    error.message = `Failed to get minichef v2 erc-20 rewarders data; ${error.message}`
    console.error(error)
    return null
  }
}
