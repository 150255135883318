import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { BasicPoolsContext } from "./BasicPoolsProvider"
import { BigNumber } from "@ethersproject/bignumber"
import { GaugeContext } from "../providers/GaugeProvider"
import { GaugeReward } from "../utils/gauges"
import { SDL_TOKEN } from "../constants"
import { UserStateContext } from "./UserStateProvider"
import { Zero } from "@ethersproject/constants"
import { bnSum } from "../utils"

type GaugesWithName = {
  gaugeName: string
  address: string
  rewards: GaugeReward[]
}

type GaugeRewards = {
  gaugeName: string
  total: BigNumber
}

type PrismRewards = {
  total: BigNumber
  decimals: number
  gaugeRewards: {
    [address: string]: GaugeRewards
  } | null
}

export const PrismRewardsBalancesContext = React.createContext<PrismRewards>({
  total: Zero,
  decimals: SDL_TOKEN.decimals,
  gaugeRewards: null,
})

export default function RewardsBalancesProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const basicPools = useContext(BasicPoolsContext)
  const userState = useContext(UserStateContext)
  const { gauges } = useContext(GaugeContext)
  const [prismReward, setPrismReward] = useState<PrismRewards>({
    total: Zero,
    decimals: SDL_TOKEN.decimals,
    gaugeRewards: null,
  })

  const gaugesWithName = useMemo<GaugesWithName[]>(() => {
    if (!basicPools || !userState?.gaugeRewards) return []
    return (
      Object.values(gauges)
        .map(({ gaugeName, rewards, address }) => {
          return {
            gaugeName,
            address,
            rewards,
          }
        })
        .filter(Boolean) as GaugesWithName[]
    ).sort((a, b) => {
      const [rewardBalA, rewardBalB] = [
        userState.gaugeRewards?.[a.address]?.claimableSDL,
        userState.gaugeRewards?.[b.address]?.claimableSDL,
      ]
      return (rewardBalA || Zero).gte(rewardBalB || Zero) ? -1 : 1
    })
  }, [basicPools, gauges, userState?.gaugeRewards])

  useEffect(() => {
    const perGaugesAmount: {
      [address: string]: GaugeRewards
    } = {}
    const sumGaugesAmount =
      gaugesWithName
        .map((gauge) => {
          const poolGaugeRewards =
            userState?.gaugeRewards?.[gauge?.address || ""]
          const sumAmount =
            (poolGaugeRewards?.claimableExternalRewards || [])
              .map(({ amount }) => amount)
              .reduce(bnSum, Zero) || Zero
          perGaugesAmount[gauge.address] = {
            total: sumAmount,
            gaugeName: gauge.gaugeName,
          }
          return sumAmount
        })
        .reduce((acc, amount) => {
          return acc.add(amount || Zero)
        }, Zero) || Zero
    setPrismReward({
      total: sumGaugesAmount,
      decimals: SDL_TOKEN.decimals,
      gaugeRewards: perGaugesAmount,
    })
  }, [gaugesWithName, userState?.gaugeRewards])

  return (
    <PrismRewardsBalancesContext.Provider value={prismReward}>
      {children}
    </PrismRewardsBalancesContext.Provider>
  )
}
