import { FARMS_MAP, FarmConfigs } from "../constants/farms"
import { FarmState, useFarmState } from "../hooks/useFarmState"
import React, { ReactElement, useEffect, useState } from "react"
import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { useActiveWeb3React } from "../hooks"
import { useSelector } from "react-redux"

type FarmData = {
  ethTVL: BigNumber
  ethAPR: BigNumber
  usdTVL: BigNumber
  usdAPR: BigNumber
  state: FarmState
}

export type Farm = FarmConfigs & FarmData
export type Farms = Partial<{ [name: string]: Farm }> | null

export const FarmsContext = React.createContext<Farms>(null)

export default function FarmsProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const { chainId, library } = useActiveWeb3React()
  const [farms, setFarms] = useState<Farms>(null)
  const getFarmState = useFarmState()
  const { tokenPricesUSD, lastTransactionTimes } = useSelector(
    (state: AppState) => state.application,
  )

  useEffect(() => {
    async function fetchFarmInfos() {
      if (!chainId || !library || !tokenPricesUSD) {
        setFarms(null)
        return
      }

      const farms = await Object.values(FARMS_MAP).reduce(
        async (accPromise, farm) => {
          const acc = await accPromise
          const farmAddress = farm.addresses[chainId]
          const farmState = await getFarmState(
            farmAddress,
            farm.name,
            farm.typeAsset,
          )
          const farmData = { ...farm, state: farmState } as Farm
          return {
            ...acc,
            [farm.name]: farmData,
          }
        },
        Promise.resolve({} as Farms),
      )

      setFarms(farms)
    }
    void fetchFarmInfos()
  }, [chainId, library, lastTransactionTimes, tokenPricesUSD, getFarmState])

  return <FarmsContext.Provider value={farms}>{children}</FarmsContext.Provider>
}
