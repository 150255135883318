import { Button, Typography } from "@mui/material"
import { GITBOOK_URL } from "../constants"
import React from "react"

const GitBookButton = () => {
  return (
    <Button
      variant="outlined"
      href={GITBOOK_URL}
      target="_blank"
      rel="noreferrer"
      sx={{
        borderRadius: 10,
        px: 1,
        py: 0.5,
      }}
    >
      <Typography variant="subtitle1" color="primary">
        GITBOOK
      </Typography>
    </Button>
  )
}

export default GitBookButton
