import React from "react"
import { useSigninCheck } from "reactfire"

const AuthWrapper = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {
  const { status, data: signInCheckResult } = useSigninCheck()

  if (children == null) {
    throw new Error("Children must be provided")
  }

  if (process.env.REACT_APP_PASSWORD_LOGIN_PAGE === "true") {
    if (status === "loading") return <span>Loading...</span>
    if (!signInCheckResult.signedIn) {
      return fallback
    }
  }
  return children as JSX.Element
}

export default AuthWrapper
