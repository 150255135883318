import { Box, Button, Typography, useTheme } from "@mui/material"
import React, { ReactElement, useEffect, useState } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import AccountDetails from "./AccountDetails"
import ConnectWallet from "./ConnectWallet"
import Dialog from "./Dialog"
import { shortenAddress } from "../utils/shortenAddress"
import { useENS } from "../hooks/useENS"
import { useTranslation } from "react-i18next"
import { useUDName } from "../hooks/useUDName"

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
}

interface Web3StatusProps {
  height?: number | string
  mobile?: boolean
}

const Web3Status = ({
  height = "24px",
  mobile = false,
}: Web3StatusProps): ReactElement => {
  const { account, error } = useWeb3React()
  const theme = useTheme()
  const [modalOpen, setModalOpen] = useState(false)
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  const { t } = useTranslation()
  const { ensName } = useENS(account)
  const isUnsupportChainIdError = error instanceof UnsupportedChainIdError
  const udName = useUDName()

  // always reset to account view
  useEffect(() => {
    if (modalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalOpen])

  return (
    <Box data-testid="walletStatusContainer" width={mobile ? "100%" : "auto"}>
      <Button
        fullWidth={mobile}
        variant="contained"
        onClick={() => {
          !isUnsupportChainIdError && setModalOpen(true)
        }}
        data-testid="accountDetailButton"
        sx={{
          ml: mobile ? 0 : 2,
          borderRadius: "2px",
          backgroundColor: isUnsupportChainIdError
            ? theme.palette.error.main
            : `${theme.palette.primary.main}${account ? "80" : ""}`,
          ":hover": {
            backgroundColor: isUnsupportChainIdError
              ? theme.palette.error.main
              : `${theme.palette.primary.main}${account ? "80" : ""}`,
          },
          height: { height },
        }}
      >
        <Typography
          variant="body1"
          whiteSpace="nowrap"
          color={
            isUnsupportChainIdError || account
              ? "text.primary"
              : theme.palette.common.black
          }
        >
          {account
            ? udName || ensName || shortenAddress(account)
            : isUnsupportChainIdError
            ? "WRONG NETWORK"
            : t("connectWallet")}
        </Typography>
      </Button>
      <Dialog
        open={modalOpen}
        onClose={(): void => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {account && walletView === WALLET_VIEWS.ACCOUNT ? (
          <AccountDetails
            openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
          />
        ) : (
          <ConnectWallet onClose={(): void => setModalOpen(false)} />
        )}
      </Dialog>
    </Box>
  )
}

export default Web3Status
