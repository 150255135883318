import { Box, Button, Container, Stack } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import React from "react"

interface LoginValues {
  email: string
  password: string
}

const LoginForm = () => {
  const auth = getAuth()
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values: LoginValues) => {
            try {
              const userCredential = await signInWithEmailAndPassword(
                auth,
                values.email,
                values.password,
              )
              console.log(userCredential)
            } catch (e) {
              alert("Wrong email/password")
            }
          }}
        >
          <Form style={{ width: "250px" }}>
            <Stack spacing={2} flexDirection="column" width="100%">
              <Stack flexDirection="column" width="100%">
                <label htmlFor="email">Your email</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  autocomplete="off"
                />
              </Stack>
              <Stack flexDirection="column">
                <label htmlFor="password">Your password</label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  autocomplete="off"
                />
              </Stack>
              <Button variant="contained" color="primary" type="submit">
                Login
              </Button>
            </Stack>
          </Form>
        </Formik>
      </Box>
    </Container>
  )
}

export default LoginForm
