import React, { ReactElement, useContext, useEffect, useState } from "react"
import { FarmsContext } from "./FarmsProvider"
import { MinichefV2PoolsData } from "../interfaces/minichefV2"
import { getMinichefV2Erc1155PoolsData } from "../utils/minichefV2Erc1155"
import { getMinichefV2Erc20PoolsData } from "../utils/minichefV2Erc20"
import { getMinichefV2Erc721PoolsData } from "../utils/minichefV2Erc721"
import { useActiveWeb3React } from "../hooks"

export const MinichefV2Context =
  React.createContext<MinichefV2PoolsData | null>(null)

export default function MinichefV2Provider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const { chainId, library } = useActiveWeb3React()
  const farms = useContext(FarmsContext)
  const [poolsData, setPoolsData] = useState<MinichefV2PoolsData | null>(null)

  useEffect(() => {
    async function fetchMinichefV2PoolsData() {
      if (!chainId || !library || !farms) {
        setPoolsData(null)
        return
      }

      const [erc20PoolInfo, erc721PoolInfo, erc1155PoolInfo] =
        await Promise.all([
          getMinichefV2Erc20PoolsData(library, chainId),
          getMinichefV2Erc721PoolsData(library, chainId),
          getMinichefV2Erc1155PoolsData(library, chainId),
        ])

      setPoolsData({
        ...(erc20PoolInfo || {}),
        ...(erc721PoolInfo || {}),
        ...(erc1155PoolInfo || {}),
      } as MinichefV2PoolsData)
    }
    void fetchMinichefV2PoolsData()
  }, [chainId, farms, library])

  return (
    <MinichefV2Context.Provider value={poolsData}>
      {children}
    </MinichefV2Context.Provider>
  )
}
