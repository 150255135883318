import { AnalyticsProvider, AuthProvider, useFirebaseApp } from "reactfire"
import { PropsWithChildren, ReactElement } from "react"
import React from "react"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"

const FirebaseInitProvider = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => {
  const firebaseApp = useFirebaseApp()
  const auth = getAuth(firebaseApp)
  const analytics = typeof window !== "undefined" ? getAnalytics() : undefined
  const firebaseProvider = <AuthProvider sdk={auth}>{children}</AuthProvider>
  if (!analytics) {
    return firebaseProvider
  }
  return (
    <AnalyticsProvider sdk={analytics}>{firebaseProvider}</AnalyticsProvider>
  )
}

export default FirebaseInitProvider
