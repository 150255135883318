import { Button, useTheme } from "@mui/material"
import { ChainId, SDL_TOKEN } from "../constants"
import React, { ReactElement } from "react"
import AddOutlineIcon from "@mui/icons-material/AddOutlined"
import { ReactComponent as MetamaskLogo } from "../assets/icons/metamask.svg"
import { useActiveWeb3React } from "../hooks"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"

function AddTokenToMetamaskButton(): ReactElement | null {
  const { addToken, canAdd } = useAddTokenToMetamask({
    ...SDL_TOKEN,
  })
  const theme = useTheme()
  const { chainId } = useActiveWeb3React()

  const isClaimableNetwork =
    chainId === ChainId.MAINNET ||
    chainId === ChainId.ARBITRUM ||
    chainId === ChainId.ARBITRUM_GOERLI ||
    chainId === ChainId.HARDHAT

  return (
    <>
      {canAdd && (
        <Button
          onClick={() => addToken()}
          variant="outlined"
          color="primary"
          disabled={!isClaimableNetwork}
          data-testid="tokenAddBtn"
          sx={{
            borderColor: theme.palette.other.divider,
            mx: 1,
          }}
        >
          <AddOutlineIcon
            fontSize="medium"
            sx={{ color: theme.palette.other.divider }}
          />
          <MetamaskLogo height={32} width={32} />
        </Button>
      )}
    </>
  )
}

export default AddTokenToMetamaskButton
