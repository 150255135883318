import "react-toastify/dist/ReactToastify.css"

import { AppDispatch, AppState } from "../state"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React, { ReactElement, Suspense, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useNoahKeyContract,
  useNoahPlayerContract,
  useNoahWethUniswapV2Contract,
  useSdlWethSushiPairContract,
} from "../hooks/useContract"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import AprsProvider from "../providers/AprsProvider"
import AuthWrapper from "../components/AuthWrapper"
import { BLOCK_TIME } from "../constants"
import BasicPoolsProvider from "../providers/BasicPoolsProvider"
import ExpandedPoolsProvider from "../providers/ExpandedPoolsProvider"
import FarmRewardsBalancesProvider from "../providers/FarmRewardsBalancesProvider"
import FarmsProvider from "../providers/FarmsProvider"
import { FirebaseAppProvider } from "reactfire"
import FirebaseInitProvider from "../providers/FirebaseInitProvider"
import GaugeProvider from "../providers/GaugeProvider"
import { LocalizationProvider } from "@mui/x-date-pickers"
import LoginForm from "../components/LoginForm"
import MinichefProvider from "../providers/MinichefProvider"
import MinichefV2Provider from "../providers/MinichefV2Provider"
import Pages from "./Pages"
import PendingSwapsProvider from "../providers/PendingSwapsProvider"
import PrismProvider from "../providers/PrismProvider"
import PrismRewardsBalancesProvider from "../providers/PrismRewardsBalancesProvider"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import RewardsBalancesProvider from "../providers/RewardsBalancesProvider"
import { ToastContainer } from "react-toastify"
import TokensProvider from "../providers/TokensProvider"
import TopMenu from "../components/TopMenu"
import UserRewardsProvider from "../providers/UserRewardsProvider"
import UserStateProvider from "../providers/UserStateProvider"
import Version from "../components/Version"
import Web3ReactManager from "../components/Web3ReactManager"
import WrongNetworkModal from "../components/WrongNetworkModal"
import fetchGasPrices from "../utils/updateGasPrices"
import fetchNoahKeyAndPlayerPrices from "../utils/updateNoahKeyAndPlayerPrices"
import fetchNoahWethUniPoolInfo from "../utils/updateNoahWethUniInfo"
import fetchSdlWethSushiPoolInfo from "../utils/updateSdlWethSushiInfo"
import fetchSwapStats from "../utils/getSwapStats"
import fetchTokenPricesUSD from "../utils/updateTokenPrices"
import { firebaseConfig } from "../constants/firebaseConfig"
import getSnapshotVoteData from "../utils/getSnapshotVoteData"
import { styled } from "@mui/material"
import { useActiveWeb3React } from "../hooks"
import usePoller from "../hooks/usePoller"

const AppContainer = styled("div")(({ theme }) => {
  return {
    backgroundColor: theme.palette.common.black,
    minHeight: "100vh",
    minWidth: "100vw",
    marginRight: "calc(-1 * (100vw - 100%))",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
})

export default function App(): ReactElement {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseInitProvider>
        <AuthWrapper fallback={<LoginForm />}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Web3ReactManager>
              <FarmsProvider>
                <BasicPoolsProvider>
                  <MinichefProvider>
                    <MinichefV2Provider>
                      <GaugeProvider>
                        <TokensProvider>
                          <PrismProvider>
                            <ExpandedPoolsProvider>
                              <UserStateProvider>
                                <PricesAndVoteData>
                                  <PendingSwapsProvider>
                                    <AprsProvider>
                                      <RewardsBalancesProvider>
                                        <PrismRewardsBalancesProvider>
                                          <FarmRewardsBalancesProvider>
                                            <UserRewardsProvider>
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <AppContainer>
                                                  <TopMenu />
                                                  <Suspense fallback={null}>
                                                    <Pages />
                                                  </Suspense>
                                                  <WrongNetworkModal />
                                                  <Version />
                                                  <ToastContainer
                                                    theme="dark"
                                                    position="top-left"
                                                  />
                                                </AppContainer>
                                              </LocalizationProvider>
                                            </UserRewardsProvider>
                                          </FarmRewardsBalancesProvider>
                                        </PrismRewardsBalancesProvider>
                                      </RewardsBalancesProvider>
                                    </AprsProvider>
                                  </PendingSwapsProvider>
                                </PricesAndVoteData>
                              </UserStateProvider>
                            </ExpandedPoolsProvider>
                          </PrismProvider>
                        </TokensProvider>
                      </GaugeProvider>
                    </MinichefV2Provider>
                  </MinichefProvider>
                </BasicPoolsProvider>
              </FarmsProvider>
            </Web3ReactManager>
          </QueryClientProvider>
        </AuthWrapper>
      </FirebaseInitProvider>
    </FirebaseAppProvider>
  )
}

function PricesAndVoteData({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const sdlWethSushiPoolContract = useSdlWethSushiPairContract()
  const noahWethUniPoolContract = useNoahWethUniswapV2Contract()
  const noahKeyContract = useNoahKeyContract()
  const noahPlayerContract = useNoahPlayerContract()
  const { chainId } = useActiveWeb3React()
  const { sdlWethSushiPool, noahWethUniPool, mintPrices } = useSelector(
    (state: AppState) => state.application,
  )

  const fetchAndUpdateGasPrice = useCallback(() => {
    void fetchGasPrices(dispatch)
  }, [dispatch])
  const fetchAndUpdateTokensPrice = useCallback(() => {
    fetchTokenPricesUSD(
      dispatch,
      noahWethUniPool,
      sdlWethSushiPool,
      mintPrices,
      chainId,
    )
  }, [dispatch, noahWethUniPool, sdlWethSushiPool, mintPrices, chainId])
  const fetchAndUpdateSwapStats = useCallback(() => {
    void fetchSwapStats(dispatch)
  }, [dispatch])
  const fetchAndUpdateNoahWethUniPoolInfo = useCallback(() => {
    void fetchNoahWethUniPoolInfo(dispatch, noahWethUniPoolContract)
  }, [dispatch, noahWethUniPoolContract])
  const fetchAndUpdateSdlWethSushiPoolInfo = useCallback(() => {
    void fetchSdlWethSushiPoolInfo(dispatch, sdlWethSushiPoolContract, chainId)
  }, [dispatch, chainId, sdlWethSushiPoolContract])

  useEffect(() => {
    void fetchNoahKeyAndPlayerPrices(
      dispatch,
      noahKeyContract,
      noahPlayerContract,
    )
  }, [dispatch, noahKeyContract, noahPlayerContract])

  useEffect(() => {
    void getSnapshotVoteData(dispatch)
  }, [dispatch])

  useEffect(() => {
    void fetchTokenPricesUSD(
      dispatch,
      noahWethUniPool,
      sdlWethSushiPool,
      mintPrices,
      chainId,
    )
  }, [chainId, dispatch, mintPrices, noahWethUniPool, sdlWethSushiPool])

  usePoller(fetchAndUpdateGasPrice, 5 * 1000)
  usePoller(fetchAndUpdateTokensPrice, BLOCK_TIME * 3)
  usePoller(fetchAndUpdateNoahWethUniPoolInfo, BLOCK_TIME * 3)
  usePoller(fetchAndUpdateSdlWethSushiPoolInfo, BLOCK_TIME * 3)
  usePoller(fetchAndUpdateSwapStats, BLOCK_TIME * 280) // ~ 1hr
  return <>{children}</>
}
