import { FarmType } from "../constants"
import MINICHEF_V2_ERC1155_CONTRACT_ABI from "../constants/abis/minichefV2Erc1155.json"
import MINICHEF_V2_ERC20_CONTRACT_ABI from "../constants/abis/minichefV2Erc20.json"
import MINICHEF_V2_ERC721_CONTRACT_ABI from "../constants/abis/minichefV2Erc721.json"
import { MinichefV2Erc1155 } from "../../types/ethers-contracts/MinichefV2Erc1155"
import { MinichefV2Erc20 } from "../../types/ethers-contracts/MinichefV2Erc20"
import { MinichefV2Erc721 } from "../../types/ethers-contracts/MinichefV2Erc721"
import { Web3Provider } from "@ethersproject/providers"
import { getContract } from "."

function getStakingContract(
  typeAsset: FarmType,
  address: string,
  account: string,
  library: Web3Provider,
): MinichefV2Erc20 | MinichefV2Erc721 | MinichefV2Erc1155 | null {
  if (!account || !address) return null

  switch (typeAsset) {
    case FarmType.ERC1155:
      return getContract(
        address,
        MINICHEF_V2_ERC1155_CONTRACT_ABI,
        library,
        account,
      ) as MinichefV2Erc1155
    case FarmType.ERC721:
      return getContract(
        address,
        MINICHEF_V2_ERC721_CONTRACT_ABI,
        library,
        account,
      ) as MinichefV2Erc721
    default:
      return getContract(
        address,
        MINICHEF_V2_ERC20_CONTRACT_ABI,
        library,
        account,
      ) as MinichefV2Erc20
  }
}

export default getStakingContract
