import { NOAH_KEY_TOKEN, NOAH_PLAYER_TOKEN } from "../constants"
import { AddressZero } from "@ethersproject/constants"
import { AppDispatch } from "../state"
import { NoahKey } from "../../types/ethers-contracts/NoahKey"
import { NoahPlayer } from "../../types/ethers-contracts/NoahPlayer"
import { updateNoahKeyAndPlayerPrices } from "../state/application"

export default async function fetchNoahKeyAndPlayerPrices(
  dispatch: AppDispatch,
  noahKeyContract: NoahKey | null,
  noahPlayerContract: NoahPlayer | null,
): Promise<void> {
  if (!noahKeyContract || !noahPlayerContract) {
    return
  }

  const [noahKeyPrice, noahPlayerPrice] = await Promise.all([
    noahKeyContract.mintPrice("1"),
    noahPlayerContract.salePrice(AddressZero),
  ])

  dispatch(
    updateNoahKeyAndPlayerPrices({
      [NOAH_KEY_TOKEN.symbol]: noahKeyPrice,
      [NOAH_PLAYER_TOKEN.symbol]: noahPlayerPrice,
    }),
  )
}
