import { injectedMetaMaskProvider, walletconnect } from "../connectors"
import { AbstractConnector } from "@web3-react/abstract-connector"
import { BasicToken } from "../providers/TokensProvider"
import { BigNumber } from "@ethersproject/bignumber"
import metamaskIcon from "../assets/icons/metamask.svg"
import { parseUnits } from "ethers/lib/utils"
import walletconnectIcon from "../assets/icons/walletconnect.svg"

// Context keys
export const NetworkContextName = "NETWORK"

// Networks config
export enum ChainId {
  MAINNET = 1,
  HARDHAT = 31337,
  PAC = 696969,
  BLAST_SEPOLIA = 168587773,
  BASE_SEPOLIA = 84532,
  BASE_MAINNET = 8453,
  ARBITRUM = 42161,
  ARBITRUM_GOERLI = 421613,
  // OPTIMISM = 10,
}

// Pool configs
export const BTC_POOL_NAME = "BTC"
export const FRAX_USDT_METAPOOL_NAME = "FRAXBP-USDT"

export type PoolName = typeof FRAX_USDT_METAPOOL_NAME | typeof BTC_POOL_NAME

export enum PoolTypes {
  BTC,
  ETH,
  USD,
  OTHER,
}

// Farm configs
export const NOAH_TOWER_PLAYER_FARM_NAME = "NOAH TOWER PLAYER"
export const NOAH_MASTER_KEY_FARM_NAME = "NOAH MASTER KEY"
export const NOAH_WETH_LP_FARM_NAME = "NOAH-WETH LP"
export const NOAH_FARM_NAME = "NOAH"
export const WRAPPED_ETH_FARM_NAME = "wETH"
export const WRAPPED_BTC_FARM_NAME = "wBTC"
export const USDC_FARM_NAME = "USDC.e"
export const ARB_FARM_NAME = "ARB"
export const AERO_FARM_NAME = "AERO"
export const BRETT_FARM_NAME = "BRETT"
export const CBETH_FARM_NAME = "cbETH"
export const DEGEN_FARM_NAME = "DEGEN"
export const NORMIE_FARM_NAME = "NORMIE"
export const USDBC_FARM_NAME = "USDbC"

export type FarmName =
  | typeof NOAH_TOWER_PLAYER_FARM_NAME
  | typeof NOAH_MASTER_KEY_FARM_NAME
  | typeof NOAH_WETH_LP_FARM_NAME
  | typeof NOAH_FARM_NAME
  | typeof WRAPPED_ETH_FARM_NAME
  | typeof WRAPPED_BTC_FARM_NAME
  | typeof USDC_FARM_NAME
  | typeof ARB_FARM_NAME
  | typeof AERO_FARM_NAME
  | typeof BRETT_FARM_NAME
  | typeof CBETH_FARM_NAME
  | typeof DEGEN_FARM_NAME
  | typeof NORMIE_FARM_NAME
  | typeof USDBC_FARM_NAME

export enum FarmType {
  ERC20,
  ERC721,
  ERC1155,
}

const buildAddresses = (
  addresses: Partial<Record<ChainId, string>>,
): Record<ChainId, string> => {
  return Object.keys(ChainId).reduce((acc, id) => {
    const numId = Number(id) as ChainId
    return { ...acc, [numId]: addresses?.[numId] || "" }
  }, {}) as Record<ChainId, string>
}

export const buildPids = (
  pids: Partial<Record<ChainId, number>>,
): Record<ChainId, number | null> => {
  // @dev be careful to include pid 0 in this boolean logic
  return Object.keys(ChainId).reduce((acc, id) => {
    const numId = Number(id) as ChainId
    const pid = pids[numId]
    return { ...acc, [numId]: pid == null ? null : pid }
  }, {}) as Record<ChainId, number | null>
}

export class Token {
  readonly addresses: { [chainId in ChainId]: string }
  readonly decimals: number
  readonly symbol: string
  readonly name: string
  readonly geckoId: string
  readonly isSynthetic: boolean
  readonly isLPToken: boolean

  constructor(
    addresses: { [chainId in ChainId]: string },
    decimals: number,
    symbol: string,
    geckoId: string,
    name: string,
    isSynthetic = false,
    isLPToken = false,
  ) {
    this.addresses = addresses
    this.decimals = decimals
    this.symbol = symbol
    this.geckoId = geckoId
    this.name = name
    this.isSynthetic = isSynthetic
    this.isLPToken = isLPToken
  }
}

export const BLOCK_TIME = 13000 // ms

export const PERMISSIONLESS_DEPLOYER_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const MASTER_REGISTRY_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const SDL_WETH_SUSHI_LP_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_WETH_UNISWAP_LP_V2_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xb93251f66364Bf0a4B3905AD9E3dD68192399bEf",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const SYNTHETIX_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const PRISM_VEST_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const BRIDGE_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const MINICHEF_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const RETROACTIVE_VESTING_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const GENERALIZED_SWAP_MIGRATOR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const RETROACTIVE_SDL_MERKLETREE_DATA = buildAddresses({
  [ChainId.HARDHAT]: "hardhat.json",
})

export const SDL_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const VOTING_ESCROW_CONTRACT_ADDRESS = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const FEE_DISTRIBUTOR_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const MULTI_FEE_DISTRIBUTION_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const GAUGE_MINTER_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const SPA_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const BTC_SWAP_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const GAUGE_HELPER_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const GAUGE_CONTROLLER_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_PLAYER_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const MINICHEF_V2_ERC721_POOL_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x59b670e9fA9D0A427751Af201D676719a970857b",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_PLAYER_TOKEN = new Token(
  NOAH_PLAYER_CONTRACT_ADDRESSES,
  18,
  "NOAH-PLAYER",
  "",
  "NOAH Tower Player",
)

export const NOAH_KEY_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_KEY_TOKEN = new Token(
  NOAH_KEY_CONTRACT_ADDRESSES,
  18,
  "NOAH-KEY",
  "",
  "NOAH Master Key",
)

export const MINICHEF_V2_ERC1155_POOL_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x7a2088a1bFc9d81c55368AE168C2C02570cB814F",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xc5a5C42992dECbae36851359345FE25997F5C42d",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_TOKEN = new Token(
  NOAH_TOKEN_ADDRESSES,
  18,
  "NOAH",
  "",
  "NOAH",
)

export const ES_NOAH_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const ES_NOAH_TOKEN = new Token(
  ES_NOAH_TOKEN_ADDRESSES,
  18,
  "esNOAH",
  "",
  "esNOAH",
)

export const WETH_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const WETH_TOKEN = new Token(
  WETH_TOKEN_ADDRESSES,
  18,
  "WETH",
  "weth",
  "WETH",
)

export const USDC_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x7A9Ec1d04904907De0ED7b6839CcdD59c3716AC9",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const USDC_TOKEN = new Token(
  USDC_TOKEN_ADDRESSES,
  6,
  "USDC.e",
  "usd-coin",
  "USDC.e",
)

export const ARB_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x49fd2BE640DB2910c2fAb69bB8531Ab6E76127ff",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const ARB_TOKEN = new Token(ARB_TOKEN_ADDRESSES, 18, "ARB", "arb", "ARB")

export const AERO_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const AERO_TOKEN = new Token(
  AERO_TOKEN_ADDRESSES,
  18,
  "AERO",
  "aerodrome-finance",
  "AERO",
)

export const BRETT_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const BRETT_TOKEN = new Token(
  BRETT_TOKEN_ADDRESSES,
  18,
  "BRETT",
  "based-brett",
  "BRETT",
)

export const CBETH_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const CBETH_TOKEN = new Token(
  CBETH_TOKEN_ADDRESSES,
  18,
  "cbETH",
  "coinbase-wrapped-staked-eth",
  "cbETH",
)

export const DEGEN_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const DEGEN_TOKEN = new Token(
  DEGEN_TOKEN_ADDRESSES,
  18,
  "DEGEN",
  "degen-base",
  "DEGEN",
)

export const NORMIE_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NORMIE_TOKEN = new Token(
  NORMIE_TOKEN_ADDRESSES,
  9,
  "NORMIE",
  "normie-2",
  "NORMIE",
)

export const USDBC_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const USDBC_TOKEN = new Token(
  USDBC_TOKEN_ADDRESSES,
  6,
  "USDbC",
  "bridged-usd-coin-base",
  "USDbC",
)

export const NOAH_WETH_LP_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0xb93251f66364Bf0a4B3905AD9E3dD68192399bEf",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const NOAH_WETH_LP_TOKEN = new Token(
  NOAH_WETH_LP_TOKEN_ADDRESSES,
  18,
  "NOAH-WETH LP",
  "",
  "NOAH-WETH LP",
  false,
  true,
)

export const SDL_TOKEN = new Token(
  SDL_TOKEN_ADDRESSES,
  18,
  "SDL",
  "saddle-finance", // Updated per CoinGecko
  "Saddle DAO",
  false,
  false,
)

const WBTC_TOKEN_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "0x4C2F7092C2aE51D986bEFEe378e50BD4dB99C901",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const WBTC_TOKEN = new Token(
  WBTC_TOKEN_ADDRESSES,
  8,
  "WBTC",
  "wrapped-bitcoin",
  "WBTC",
)

export const BTC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.HARDHAT]: "",
  [ChainId.PAC]: "",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "",
  [ChainId.BASE_MAINNET]: "",
  [ChainId.MAINNET]: "",
  [ChainId.ARBITRUM]: "",
  [ChainId.ARBITRUM_GOERLI]: "",
})

export const BTC_SWAP_TOKEN = new Token(
  BTC_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "saddleBTC",
  "saddlebtc",
  "Saddle TBTC/WBTC/RENBTC/SBTC",
  false,
  true,
)

export const BTC_POOL_TOKENS = [WBTC_TOKEN]

export type Pool = {
  name: PoolName
  lpToken: Token
  poolTokens: Token[]
  isSynthetic: boolean
  addresses: { [chainId in ChainId]: string }
  type: PoolTypes
  route: string
  metaSwapAddresses?: { [chainId in ChainId]: string }
  underlyingPoolTokens?: Token[]
  underlyingPool?: PoolName
  isOutdated?: boolean // pool can be outdated but not have a migration target
  rewardPids: { [chainId in ChainId]: number | null }
  isGuarded?: boolean
}

export type PoolsMap = {
  [poolName: string]: Pool
}

export const POOLS_MAP: PoolsMap = {
  [BTC_POOL_NAME]: {
    name: BTC_POOL_NAME,
    addresses: BTC_SWAP_ADDRESSES,
    lpToken: BTC_SWAP_TOKEN,
    poolTokens: BTC_POOL_TOKENS,
    isSynthetic: true,
    type: PoolTypes.BTC,
    route: "btc",
    isOutdated: true,
    rewardPids: buildPids({}),
    isGuarded: true,
  },
}

// @dev note that metapools refer to the deposit addresses and not the meta addresses
const minichefPids: Partial<Record<ChainId, { [pool: string]: number }>> = {
  [ChainId.MAINNET]: {},
  [ChainId.HARDHAT]: {},
}

export function getMinichefPid(
  chainId: ChainId,
  poolAddress: string,
): number | null {
  return minichefPids?.[chainId]?.[poolAddress] || null
}

export function isGuardedPool(poolName: string): boolean {
  return poolName === BTC_POOL_NAME
}

/**
 * These pools use SwapV1, SwapFlashLoanV1 or SwapGuarded abi, those being the only swaps with a WithdrawFee
 */
export function isWithdrawFeePool(poolName: string): boolean {
  return new Set([BTC_POOL_NAME]).has(poolName)
}

export function isMetaPool(poolName = ""): boolean {
  return new Set([FRAX_USDT_METAPOOL_NAME]).has(poolName)
}

// maps a symbol string to a token object
export type TokensMap = {
  [symbol: string]: Token
}

export type BasicTokensMap = {
  [symbol: string]: BasicToken | undefined
}

export const TOKENS_MAP = Object.keys(POOLS_MAP).reduce((acc, poolName) => {
  const pool = POOLS_MAP[poolName as PoolName]
  const newAcc = { ...acc }
  pool.poolTokens.forEach((token) => {
    newAcc[token.symbol] = token
  })
  newAcc[pool.lpToken.symbol] = pool.lpToken
  return newAcc
}, {} as TokensMap)

export type TokenToPoolsMap = {
  [tokenSymbol: string]: string[]
}

export const TOKEN_TO_POOLS_MAP = Object.keys(POOLS_MAP).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName as PoolName]
    const newAcc = { ...acc }
    pool.poolTokens.forEach((token) => {
      newAcc[token.symbol] = (newAcc[token.symbol] || []).concat(
        poolName as PoolName,
      )
    })
    return newAcc
  },
  {} as TokenToPoolsMap,
)

export const TRANSACTION_TYPES = {
  MINT: "MINT",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  MIGRATE: "MIGRATE",
  STAKE_OR_CLAIM: "STAKE_OR_CLAIM",
  REDEEM: "REDEEM",
  VEST_CLAIM_OR_CANCEL: "VEST_CLAIM_OR_CANCEL",
  PRSM_TOKEN_STAKE_OR_UNSTAKE: "PRSM_TOKEN_STAKE_OR_UNSTAKE",
  PRSM_TOKEN_LOCK_OR_UNLOCK: "PRSM_TOKEN_LOCK_OR_UNLOCK",
  PRSM_TOKEN_CLAIM_WITH_PENALTY: "PRSM_TOKEN_CLAIM_WITH_PENALTY",
  PRSM_TOKEN_CLAIM_WITHOUT_PENALTY: "PRSM_TOKEN_CLAIM_WITHOUT_PENALTY",
}

export const POOL_FEE_PRECISION = 10

export enum SWAP_TYPES {
  DIRECT = "swapDirect", // route length 2
  SYNTH_TO_SYNTH = "swapSynthToSynth", // route length 2
  SYNTH_TO_TOKEN = "swapSynthToToken", // route length 3
  TOKEN_TO_SYNTH = "swapTokenToSynth", // route length 3
  TOKEN_TO_TOKEN = "swapTokenToToken", // route length 4
  INVALID = "invalid",
}

export function getIsVirtualSwap(swapType: SWAP_TYPES): boolean {
  return (
    swapType === SWAP_TYPES.SYNTH_TO_SYNTH ||
    swapType === SWAP_TYPES.SYNTH_TO_TOKEN ||
    swapType === SWAP_TYPES.TOKEN_TO_SYNTH ||
    swapType === SWAP_TYPES.TOKEN_TO_TOKEN
  )
}

export const SWAP_CONTRACT_GAS_ESTIMATES_MAP = {
  [SWAP_TYPES.INVALID]: BigNumber.from("999999999"), // 999,999,999
  [SWAP_TYPES.DIRECT]: BigNumber.from("200000"), // 157,807
  [SWAP_TYPES.TOKEN_TO_TOKEN]: BigNumber.from("2000000"), // 1,676,837
  [SWAP_TYPES.TOKEN_TO_SYNTH]: BigNumber.from("2000000"), // 1,655,502
  [SWAP_TYPES.SYNTH_TO_TOKEN]: BigNumber.from("1500000"), // 1,153,654
  [SWAP_TYPES.SYNTH_TO_SYNTH]: BigNumber.from("700000"), // 681,128 // TODO: https://github.com/saddle-finance/saddle-frontend/issues/471
  addLiquidity: BigNumber.from("400000"), // 386,555
  removeLiquidityImbalance: BigNumber.from("350000"), // 318,231
  removeLiquidityOneToken: BigNumber.from("250000"), // 232,947
  migrate: BigNumber.from("650000"), // 619,126
  virtualSwapSettleOrWithdraw: BigNumber.from("400000"),
}

export interface WalletInfo {
  name: string
  icon: string
  connector: AbstractConnector
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // TALLY: {
  //   name: "Tally",
  //   icon: tallyIcon,
  //   connector: injectedTallyProvider,
  // },
  METAMASK: {
    name: "MetaMask",
    icon: metamaskIcon,
    connector: injectedMetaMaskProvider,
  },
  // UNSTOPPABLE_DOMAINS: {
  //   name: "Unstoppable Domains",
  //   icon: unstoppableDomainsLogo,
  //   connector: uauth,
  // },
  WALLET_CONNECT: {
    name: "WalletConnect",
    icon: walletconnectIcon,
    connector: walletconnect,
  },
  // WALLET_LINK: {
  //   name: "Coinbase Wallet",
  //   icon: coinbasewalletIcon,
  //   connector: walletlink,
  // },
}

// derived from https://docs.synthetix.io/tokens/list/
export const SYNTHETIX_TOKENS: { [chainId in ChainId]?: string[] } = {
  [ChainId.MAINNET]: [
    "0xd2df355c19471c8bd7d8a3aa27ff4e26a21b4076", // Aave (sAAVE)
    "0xf48e200eaf9906362bb1442fca31e0835773b8b4", // Australian Dollars (sAUD)
    "0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6", // Bitcoin (sBTC)
    "0xe36e2d3c7c34281fa3bc737950a68571736880a1", // Cardano (sADA)
    "0xbbc455cb4f1b9e4bfc4b73970d360c8f032efee6", // Chainlink (sLINK)
    "0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b", // DeFi Index (sDEFI)
    "0x104edf1da359506548bfc7c25ba1e28c16a70235", // ETH / BTC (sETHBTC)
    "0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb", // Ether (sETH)
    "0xd71ecff9342a5ced620049e616c5035f1db98620", // Euros (sEUR)
    "0xf6b1c627e95bfc3c1b4c9b825a032ff0fbf3e07d", // Japanese Yen (sJPY)
    "0x1715ac0743102bf5cd58efbb6cf2dc2685d967b6", // Polkadot (sDOT)
    "0x97fe22e7341a0cd8db6f6c021a24dc8f4dad855f", // Pound Sterling (sGBP)
    "0x269895a3df4d73b077fc823dd6da1b95f72aaf9b", // South Korean Won (sKRW)
    "0x0f83287ff768d1c1e17a42f44d644d7f22e8ee1d", // Swiss Franc (sCHF)
    "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f", // Synthetix (SNX)
    "0x57ab1ec28d129707052df4df418d58a2d46d5f51", // US Dollars (sUSD)
  ],
  [ChainId.HARDHAT]: [],
}

// "SADDLE" in bytes32 form
export const SYNTH_TRACKING_ID = ""

// FLAGS
export const IS_VIRTUAL_SWAP_ACTIVE = true
export const IS_L2_SUPPORTED = true
export const IS_SDL_LIVE = true
export const IS_VESDL_LIVE = true
export const IS_POOL_REGISTRY_MIGRATION_LIVE = true
export const IS_DEFAULT_INFINITE_APPROVAL = true
// FLAGS END

// Regex for readable decimal number
export const readableDecimalNumberRegex = /^[0-9]*[.,]?[0-9]*$/

// Numbers and durations
export const BN_1E18 = BigNumber.from(10).pow(18)
export const BN_DAY_IN_SECONDS = BigNumber.from(24 * 60 * 60)
export const BN_YEAR_IN_SECONDS = BN_DAY_IN_SECONDS.mul(365)
export const BN_MSIG_SDL_VEST_END_TIMESTAMP = BigNumber.from(1731651563)
export const A_PRECISION = BigNumber.from(100)
export const N_OF_PERIODS = parseUnits((52.12).toFixed(2), 18)

// URIs
export const PRISM_GITHUB_URI = "https://www.google.com"
export const PRISM_AUDIT_URI = "https://www.google.com"
export const PRISM_ABOUT_URI = "https://www.google.com"

// URLs
export const NOAH_MAIN_WEBSITE_URL = "https://www.joinnoah.xyz/"
export const TOKEN_AVATAR_BASE_URL =
  "https://storage.googleapis.com/noah-tower-avatar-test"
export const GITBOOK_URL = "https://www.google.com"
export const MINT_PLAYER_URL = "https://game.joinnoah.xyz/mintplayer"
export const PLAY_GAME_URL = "https://game.joinnoah.xyz/play"
export const TWITTER_URL = "https://twitter.com/joinnoah"
export const DISCORD_URL = "https://www.google.com"
export const ADD_LP_URL = "https://www.google.com"
export const BUY_PLATFORM_NATIVE_TOKEN_URL =
  "https://app.1inch.io/#/42161/simple/swap/ETH/NOAH"
export const BRIDGE_URL = "https://bridge.arbitrum.io/?l2ChainId=42161"
export const GET_TOKEN_URL = "https://app.1inch.io/#/42161/simple/swap/ETH/"

// CALCULATE THE TOTAL CIRCULATING NOAH e.g.(WALLET's, TEAM TREASURY, esNOAH CONTRACT)
export const CIRC_NOAH_ACCOUNT_ADDRESS: { [chainId in ChainId]?: string[] } = {
  [ChainId.HARDHAT]: [
    "0x5FbDB2315678afecb367f032d93F642f64180aa3", // Treasury / Development Fund (TeamBucket)
    "0xBef711956AabBE7b0fCB05Fa5CBF26B8A2C6017F", // Marketing Budget at Launch
    "0x0000000000000000000000000000000000000000", // Initial NOAH-ETH LP (Deployer address)
    "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9", // esNOAH Contract
    "0x0000000000000000000000000000000000000000", // Vesting Penalty
    "0x0000000000000000000000000000000000000000", // Tower Game Incentive (Wallet to claim & disperse)
    "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e", // Seasonal rebate (master key) (Master Key contract)
    "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707", // Seasonal rebate (player) (Player contract)
    "0x000000000000000000000000000000000000dEaD", // Burn $NOAH
  ],
  [ChainId.PAC]: [
    "0x0000000000000000000000000000000000000000", // Treasury / Development Fund (TeamBucket)
    "0x0000000000000000000000000000000000000000", // Marketing Budget at Launch
    "0x0000000000000000000000000000000000000000", // Initial NOAH-ETH LP (Deployer address)
    "0x0000000000000000000000000000000000000000", // esNOAH Contract
    "0x0000000000000000000000000000000000000000", // Vesting Penalty
    "0x0000000000000000000000000000000000000000", // Tower Game Incentive (Wallet to claim & disperse)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (master key) (Master Key contract)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (player) (Player contract)
    "0x0000000000000000000000000000000000000000", // Burn $NOAH
  ],
  [ChainId.BLAST_SEPOLIA]: [
    "0x0000000000000000000000000000000000000000", // Treasury / Development Fund (TeamBucket)
    "0x0000000000000000000000000000000000000000", // Marketing Budget at Launch
    "0x0000000000000000000000000000000000000000", // Initial NOAH-ETH LP (Deployer address)
    "0x0000000000000000000000000000000000000000", // esNOAH Contract
    "0x0000000000000000000000000000000000000000", // Vesting Penalty
    "0x0000000000000000000000000000000000000000", // Tower Game Incentive (Wallet to claim & disperse)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (master key) (Master Key contract)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (player) (Player contract)
    "0x0000000000000000000000000000000000000000", // Burn $NOAH
  ],
  [ChainId.BASE_MAINNET]: [
    "0x0000000000000000000000000000000000000000", // Treasury / Development Fund (TeamBucket)
    "0x0000000000000000000000000000000000000000", // Marketing Budget at Launch
    "0x0000000000000000000000000000000000000000", // Initial NOAH-ETH LP (Deployer address)
    "0x0000000000000000000000000000000000000000", // esNOAH Contract
    "0x0000000000000000000000000000000000000000", // Vesting Penalty
    "0x0000000000000000000000000000000000000000", // Tower Game Incentive (Wallet to claim & disperse)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (master key) (Master Key contract)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (player) (Player contract)
    "0x0000000000000000000000000000000000000000", // Burn $NOAH
  ],
  [ChainId.BASE_SEPOLIA]: [
    "0x0000000000000000000000000000000000000000", // Treasury / Development Fund (TeamBucket)
    "0x0000000000000000000000000000000000000000", // Marketing Budget at Launch
    "0x0000000000000000000000000000000000000000", // Initial NOAH-ETH LP (Deployer address)
    "0x0000000000000000000000000000000000000000", // esNOAH Contract
    "0x0000000000000000000000000000000000000000", // Vesting Penalty
    "0x0000000000000000000000000000000000000000", // Tower Game Incentive (Wallet to claim & disperse)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (master key) (Master Key contract)
    "0x0000000000000000000000000000000000000000", // Seasonal rebate (player) (Player contract)
    "0x0000000000000000000000000000000000000000", // Burn $NOAH
  ],
}

// Countdown time calculation
export const AVG_BLOCK_TIME = 12

export const PRESALE_CONFIG = {
  PRICE: "0.25",
  DEPOSIT_WALLET: "0xd38f9066d9FdA721A0854e98d4670A84498fb40B",
  REBATE_AMOUNT: 2500,
}

// Master Key configuration
export const MASTER_KEY_MINT_PER_TRAN = 100
export const MASTER_KEY_MINT_FREE_PLAYER_AIRDROP = 1
