import React, { ReactElement, useCallback, useContext, useState } from "react"
import { BLOCK_TIME } from "../constants"
import { BigNumber } from "@ethersproject/bignumber"
import { FarmsContext } from "./FarmsProvider"
import { getMinichefV2Erc1155UsersData } from "../utils/minichefV2Erc1155"
import { getMinichefV2Erc20UsersData } from "../utils/minichefV2Erc20"
import { getMinichefV2Erc721UsersData } from "../utils/minichefV2Erc721"
import { useActiveWeb3React } from "../hooks"
import usePoller from "../hooks/usePoller"

type UserRewards = {
  [farmName: string]: UserReward
}

type UserReward = {
  amountStaked: BigNumber
  claimableNOAH: BigNumber
  claimableExternalReward: { amount: BigNumber; token: string }
}

export const UserRewardsContext = React.createContext<UserRewards | null>(null)

export default function UserRewardsProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const { chainId, library, account } = useActiveWeb3React()
  const [userRewards, setUserRewards] = useState<UserRewards | null>(null)
  const farmsData = useContext(FarmsContext)

  const fetchState = useCallback(() => {
    async function fetchUserRewards() {
      if (!chainId || !library || !account || !farmsData) return

      const erc20UserInfoPromise = getMinichefV2Erc20UsersData(
        library,
        chainId,
        account,
      )
      const erc721UserInfoPromise = getMinichefV2Erc721UsersData(
        library,
        chainId,
        account,
      )
      const erc1155UserInfoPromise = getMinichefV2Erc1155UsersData(
        library,
        chainId,
        account,
      )

      const [erc20UserInfo, erc721UserInfo, erc1155UserInfo] =
        await Promise.all([
          erc20UserInfoPromise,
          erc721UserInfoPromise,
          erc1155UserInfoPromise,
        ])

      const combinationUserInfos = {
        ...erc20UserInfo,
        ...erc721UserInfo,
        ...erc1155UserInfo,
      }

      setUserRewards(
        Object.keys(combinationUserInfos || {}).reduce(
          (rewardsAcc, farmName) => {
            const newRewardsAcc = { ...rewardsAcc }
            newRewardsAcc[farmName] = {
              amountStaked: combinationUserInfos[farmName].amountStaked,
              claimableNOAH: combinationUserInfos[farmName].pendingNoah,
              claimableExternalReward: {
                amount:
                  combinationUserInfos[farmName].pendingToken?.rewardAmount,
                token: combinationUserInfos[farmName].pendingToken?.rewardToken,
              },
            } as UserReward
            return newRewardsAcc
          },
          {} as UserRewards,
        ),
      )
    }
    void fetchUserRewards()
  }, [account, chainId, farmsData, library])

  usePoller(fetchState, BLOCK_TIME, [fetchState])

  return (
    <UserRewardsContext.Provider value={userRewards}>
      {children}
    </UserRewardsContext.Provider>
  )
}
