import React, { ReactElement, useContext, useEffect, useState } from "react"
import { AppState } from "../state"
import { BasicPoolsContext } from "./BasicPoolsProvider"
import { BigNumber } from "ethers"
import { Zero } from "@ethersproject/constants"
import { useActiveWeb3React } from "../hooks"
import { usePrismContract } from "../hooks/useContract"
import { useSelector } from "react-redux"

export const PrismContext = React.createContext<PrismData | null>(null)

export type PrismData = {
  balance: BigNumber
}

export default function PrismProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const { chainId, account, library } = useActiveWeb3React()
  const pools = useContext(BasicPoolsContext)
  const [prismsData, setPrismsData] = useState<PrismData | null>(null)
  const { lastTransactionTimes } = useSelector(
    (state: AppState) => state.application,
  )
  const prismContract = usePrismContract()

  useEffect(() => {
    async function fetchTokens() {
      if (!account || !chainId || !library || !pools || !prismContract) {
        setPrismsData(null)
        return
      }
      const prismTokenBalance = await prismContract.balanceOf(account)
      setPrismsData({
        balance: prismTokenBalance || Zero,
      })
    }
    void fetchTokens()
  }, [account, chainId, library, pools, prismContract, lastTransactionTimes])

  return (
    <PrismContext.Provider value={prismsData}>{children}</PrismContext.Provider>
  )
}
