import { Button, useTheme } from "@mui/material"
import React, { SyntheticEvent, useMemo } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { IS_L2_SUPPORTED } from "../constants"
import { getNetworkIconPath } from "../utils"

interface NetworkDisplayProps extends React.ComponentPropsWithoutRef<"button"> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
const NetworkDisplay = React.forwardRef<HTMLButtonElement, NetworkDisplayProps>(
  function NetworkDisplay({ onClick }, ref) {
    const { active, chainId, error } = useWeb3React()
    const theme = useTheme()
    const isUnsupportChainIdError = error instanceof UnsupportedChainIdError

    const handleImageError = (
      event: SyntheticEvent<HTMLImageElement, Event>,
    ) => {
      event.currentTarget.src = getNetworkIconPath()
    }

    const iconUrl = useMemo(() => {
      return getNetworkIconPath(
        !isUnsupportChainIdError ? chainId : "wrong_network",
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, active, isUnsupportChainIdError])

    return (
      IS_L2_SUPPORTED && (
        <Button
          ref={ref}
          data-testid="networkDisplayBtn"
          variant="contained"
          size="small"
          onClick={onClick}
          sx={{
            zIndex: theme.zIndex.modal,
            ml: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2px",
            width: 24,
            height: 24,
            backgroundColor: theme.palette.other.darkCharcoal,
            "&:hover": {
              backgroundColor: theme.palette.other.darkCharcoal,
            },
          }}
        >
          {iconUrl && (
            <img
              src={iconUrl}
              alt=""
              height={16}
              width={16}
              onError={handleImageError}
            />
          )}
        </Button>
      )
    )
  },
)
export default NetworkDisplay
