import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  LinkProps,
  Stack,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  BRIDGE_URL,
  MINT_PLAYER_URL,
  NOAH_MAIN_WEBSITE_URL,
  PLAY_GAME_URL,
} from "../constants"
import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material"
import React, {
  HTMLAttributeAnchorTarget,
  ReactElement,
  useEffect,
  useState,
} from "react"
import { useHistory, useLocation } from "react-router-dom"
import GitBookButton from "./GitBookButton"
import NetworkDisplay from "./NetworkDisplay"
import { ReactComponent as NoahLogo } from "../assets/icons/logo_full.svg"
import { SocialBar } from "./SocialBar"
import SquareRoundedIcon from "@mui/icons-material/SquareRounded"
import TermsDialog from "./TermsDialog"
import TokenClaimDialog from "./TokenClaimDialog"
import Web3Status from "./Web3Status"
import { useTranslation } from "react-i18next"

type ActiveTabType = "" | "mintmasterkey" | "farm"
const topNavHeight = 36

const StyledNavMenu = styled(
  (props: LinkProps & { selected: boolean; leading?: boolean }) => {
    const theme = useTheme()
    const isUnderLaptopSize = useMediaQuery("(max-width:1100px)")
    return (
      <>
        <Stack
          display="flex"
          alignItems="center"
          direction="row"
          ml={isUnderLaptopSize ? 0 : 2}
          sx={[isUnderLaptopSize && { justifyContent: "center" }]}
        >
          {!isUnderLaptopSize && props.leading && (
            <SquareRoundedIcon
              sx={{
                color: props.selected
                  ? theme.palette.text.secondary
                  : theme.palette.text.primary,
                fontSize: 10,
                mr: 1,
              }}
            />
          )}
          <Link variant="subtitle1" {...props} />
        </Stack>
        {isUnderLaptopSize && (
          <Divider sx={{ borderColor: theme.palette.action.disabled }} />
        )}
      </>
    )
  },
)<LinkProps & { selected: boolean }>(({ theme, selected }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "42px",
    textDecoration: "none",
    color: selected ? theme.palette.text.secondary : theme.palette.text.primary,
    fontWeight: selected ? "bold" : "normal",
    backgroundColor: "transparent",
  }
})

const NavMenu = ({
  label,
  selected,
  onClick,
  leading = true,
  href = "",
  target = "_blank",
}: {
  label: string
  selected: boolean
  onClick?: () => void
  leading?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
}) => {
  return href ? (
    <StyledNavMenu
      href={href}
      target={target}
      selected={selected}
      leading={leading}
    >
      {label}
    </StyledNavMenu>
  ) : (
    <StyledNavMenu
      selected={selected}
      leading={leading}
      onClick={() => {
        onClick?.()
      }}
    >
      {label}
    </StyledNavMenu>
  )
}

function TopMenu(): ReactElement {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const theme = useTheme()
  const isUnderLaptopSize = useMediaQuery("(max-width:1100px)")
  const handleMoreMenu = () => {
    if (isUnderLaptopSize) {
      setDrawerOpen(!drawerOpen)
    }
  }
  useEffect(() => {
    if (!isUnderLaptopSize) {
      setDrawerOpen(false)
    }
  }, [isUnderLaptopSize])
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        px: isUnderLaptopSize ? 0 : 2,
        border: 0,
        borderRadius: 0,
        backgroundColor: theme.palette.common.black,
        minHeight: topNavHeight,
        minWidth: "100vw",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters
        data-testid="topMenuContainer"
        sx={{
          xs: 0,
          lg: 7,
          minHeight: topNavHeight,
          bgcolor: "transparent",
        }}
      >
        <Box display="flex" width="100%" alignItems="center">
          <Box flex={1} ml={isUnderLaptopSize ? 2 : 0}>
            <IconButton href={NOAH_MAIN_WEBSITE_URL}>
              <NoahLogo
                height={topNavHeight}
                style={{
                  marginTop: "1px",
                }}
              />
            </IconButton>
          </Box>

          <Stack direction="row" justifyContent="center">
            <Box display="flex" alignItems="center">
              {!isUnderLaptopSize && (
                <>
                  <MenuList setCurrentModal={setCurrentModal} />
                  <SocialBar />
                </>
              )}
              <NetworkDisplay />
              {!isUnderLaptopSize && <Web3Status />}
            </Box>
            {isUnderLaptopSize && (
              <Stack
                zIndex={theme.zIndex.modal}
                direction="row"
                flex={1}
                ml={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  onClick={handleMoreMenu}
                  data-testid="settingsMenuBtn"
                  sx={{
                    height: 36,
                    minWidth: 0,
                    maxWidth: 36,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <MenuIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.common.black,
                      display: drawerOpen ? "none" : "block",
                    }}
                  />
                  <CloseIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.common.black,
                      display: drawerOpen ? "block" : "none",
                    }}
                  />
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>

        <TokenClaimDialog
          open={currentModal === "tokenClaim"}
          onClose={(): void => setCurrentModal(null)}
        />

        <TermsDialog
          open={currentModal === "terms"}
          onClose={(): void => setCurrentModal(null)}
        />

        <Drawer
          hideBackdrop
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          anchor="right"
          PaperProps={{
            sx: {
              margin: "75px 0 0 0",
              width: "100vw",
              borderWidth: 0,
              borderRadius: 0,
              zIndex: theme.zIndex.drawer,
            },
          }}
        >
          <Stack m={(theme) => theme.spacing(0, 0, 0, 0)}>
            <Stack onClick={() => setDrawerOpen(false)}>
              <MenuList setCurrentModal={setCurrentModal} />
            </Stack>
            <Box
              py={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {isUnderLaptopSize ? (
                <Box mb={2} ml={0}>
                  <GitBookButton />
                </Box>
              ) : (
                ""
              )}
              <Box mb={2} ml={isUnderLaptopSize ? -2 : 0}>
                <SocialBar />
              </Box>
            </Box>
          </Stack>
        </Drawer>
      </Toolbar>
      {isUnderLaptopSize && (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="36px"
          zIndex={theme.zIndex.modal}
        >
          <Web3Status height="36px" mobile />
        </Box>
      )}
    </AppBar>
  )
}

function MenuList({
  setCurrentModal,
}: {
  setCurrentModal(modal: string): void
}) {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const history = useHistory()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const activeTab = pathname.split("/")[1] as ActiveTabType

  const isPresale = process.env.REACT_APP_PRESALE_ENABLE === "true"
  return (
    <React.Fragment>
      <NavMenu
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          history.push("/mintmasterkey")
        }}
        label={t("mintMasterKey")}
        selected={activeTab === "mintmasterkey"}
      />
      {isPresale ? null : (
        <>
          <NavMenu
            target="_self"
            href={MINT_PLAYER_URL}
            label={t("mintPlayer")}
            selected={false}
          />
          <NavMenu
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              history.push("/farm")
            }}
            label={t("farm")}
            selected={activeTab === "farm"}
          />
          <NavMenu
            target="_self"
            href={PLAY_GAME_URL}
            label={t("playGame")}
            selected={false}
          />
          <NavMenu href={BRIDGE_URL} label={t("bridge")} selected={false} />
        </>
      )}
      <NavMenu
        onClick={() => {
          setCurrentModal("terms")
        }}
        label={t("terms")}
        selected={false}
        leading={false}
      />
    </React.Fragment>
  )
}

export default TopMenu
