import { DialogContent, Typography } from "@mui/material"
import React, { ReactElement, useState } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import Dialog from "./Dialog"
import { Web3Provider } from "@ethersproject/providers"
import { useTranslation } from "react-i18next"

export default function WrongNetworkModal(): ReactElement {
  const [open, setOpen] = useState<boolean | undefined>()
  const { error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError = error instanceof UnsupportedChainIdError
  const { t } = useTranslation()

  return (
    <Dialog
      open={open ?? isUnsupportChainIdError}
      maxWidth="xs"
      onClose={() => setOpen(false)}
    >
      <DialogContent sx={{ whiteSpace: "pre-line", px: 6, pb: 6, pt: 3 }}>
        <Typography textAlign="left" color="primary" variant="h4" mb={3}>
          WRONG NETWORK
        </Typography>
        <Typography color="text.primary" variant="h6">
          {t("wrongNetworkContent")}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
