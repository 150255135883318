import { Components, Theme } from "@mui/material"

export default function TooltipTheme(theme: Theme): Components {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        popper: {},
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          boxShadow: theme.shadows[1],
          border: `2px solid ${theme.palette.common.black}`,
          borderRadius: 0,
          lineHeight: 16 / 12,
          fontSize: theme.typography.body2.fontSize,
        },
        arrow: {
          color: theme.palette.common.white,
          "&::before": {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.common.black}`,
          },
        },
      },
    },
  }
}
