import { ChainId } from "./index"

export const NETWORK_LABEL: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: "Ethereum",
  [ChainId.ARBITRUM]: "Arbitrum",
  [ChainId.ARBITRUM_GOERLI]: "Arbitrum Goerli",
  // [ChainId.OPTIMISM]: "Optimism",
  [ChainId.HARDHAT]: "Hardhat 👷🏼‍♂️",
  [ChainId.PAC]: "PAC Private",
  [ChainId.BLAST_SEPOLIA]: "Blast Sepolia",
  [ChainId.BASE_SEPOLIA]: "Base Sepolia",
  [ChainId.BASE_MAINNET]: "Base Mainnet",
}

// TODO: figure out better way of representing non-erc20 native tokens
export const NETWORK_NATIVE_TOKENS: Record<ChainId, string> = {
  [ChainId.MAINNET]: "ETH",
  [ChainId.ARBITRUM]: "ETH",
  [ChainId.ARBITRUM_GOERLI]: "ETH",
  // [ChainId.OPTIMISM]: "ETH",
  [ChainId.HARDHAT]: "ETH",
  [ChainId.PAC]: "PAC",
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BASE_SEPOLIA]: "ETH",
  [ChainId.BASE_MAINNET]: "ETH",
}

export const COINGECKO_PLATFORM_ID: Record<ChainId, string | null> = {
  [ChainId.MAINNET]: "ethereum",
  [ChainId.ARBITRUM]: "arbitrum-one",
  [ChainId.ARBITRUM_GOERLI]: "arbitrum-one",
  // [ChainId.OPTIMISM]: "optimistic-ethereum",
  [ChainId.HARDHAT]: null,
  [ChainId.PAC]: null,
  [ChainId.BLAST_SEPOLIA]: null,
  [ChainId.BASE_SEPOLIA]: null,
  [ChainId.BASE_MAINNET]: "base",
}

export type SupportedNetwork = {
  chainId: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

export type SupportedNetworks = Partial<{ [key in ChainId]: SupportedNetwork }>

// refer to https://github.com/sushiswap/sushiswap-interface/blob/canary/src/modals/NetworkModal/index.tsx#L13
export const SUPPORTED_NETWORKS: SupportedNetworks = {
  // [ChainId.MAINNET]: {
  //   chainId: "0x1",
  //   chainName: "Ethereum",
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://mainnet.infura.io/v3"],
  //   blockExplorerUrls: ["https://etherscan.com"],
  // },
  // [ChainId.ARBITRUM]: {
  //   chainId: "0xA4B1",
  //   chainName: "Arbitrum",
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://arb1.arbitrum.io/rpc"],
  //   blockExplorerUrls: ["https://mainnet-arb-explorer.netlify.app"],
  // },
  // [ChainId.OPTIMISM]: {
  //   chainId: "0xA",
  //   chainName: "Optimism",
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://mainnet.optimism.io"],
  //   blockExplorerUrls: ["https://optimistic.etherscan.io"],
  // },
  [ChainId.BASE_MAINNET]: {
    chainId: "0x2105",
    chainName: "Base Mainnet",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org"],
  },
}

export const DEV_SUPPORTED_NETWORKS: SupportedNetworks = {
  ...SUPPORTED_NETWORKS,
  [ChainId.ARBITRUM_GOERLI]: {
    chainId: "0x66EED",
    chainName: "Arbitrum Goerli",
    nativeCurrency: {
      name: "Arbitrum Goerli Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb-goerli.g.alchemy.com/v2"],
    blockExplorerUrls: ["https://goerli.arbiscan.io/"],
  },
  [ChainId.PAC]: {
    chainId: "0xAA289",
    chainName: "PAC Private",
    nativeCurrency: {
      name: "PAC Ether",
      symbol: "PAC",
      decimals: 18,
    },
    rpcUrls: ["http://pac.0x.studio:8545/"],
    blockExplorerUrls: ["https://pac.0x.studio/"],
  },
  [ChainId.BLAST_SEPOLIA]: {
    chainId: "0xA0C71FD",
    chainName: "Blast Sepolia",
    nativeCurrency: {
      name: "Blast Ether",
      symbol: "WETH",
      decimals: 18,
    },
    rpcUrls: ["https://blast-sepolia.blockpi.network/v1/rpc/public/"],
    blockExplorerUrls: ["https://testnet.blastscan.io/"],
  },
  [ChainId.BASE_SEPOLIA]: {
    chainId: "0x14A34",
    chainName: "Base Sepolia",
    nativeCurrency: {
      name: "Base Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://sepolia.base.org/"],
    blockExplorerUrls: ["https://sepolia-explorer.base.org/"],
  },
}

export const NETWORK_CONTEXT_NAME = "NETWORK"

export const DEFAULT_NETWORK_LABEL =
  NETWORK_LABEL[ChainId.BASE_MAINNET] || "Ethereum"
