import { PaletteOptions } from "@mui/material"

// Define custom color types

interface OtherColorTypes {
  divider: string
  innerDivider: string
  border: string
  mainGradient: string
  darkGreen80: string
  darkCharcoal: string
  yellow: string
}
declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
    alert?: {
      content?: string
      background?: string
    }
  }
  interface PaletteColor {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
    alert?: {
      content?: string
      background?: string
    }
  }
  interface Palette {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
  interface PaletteOptions {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
}

const GREY_TONES = {
  100: "#E4E4E4",
  200: "#A7A7A7",
  300: "#7D7D7D",
  500: "#404040",
  700: "#252525",
}
const lightPalette: PaletteOptions | undefined = {
  mode: "light",
  primary: {
    main: "#5FEFC0",
    dark: "#5FEFC033",
    light: "#6EAE99",
    states: {
      outlinedRestingBorder: "#5FEFC0",
      outlinedHoverBackground: "#5FEFC033",
      containedHoverBackground: "#6EAE99",
    },
  },
  secondary: {
    main: "#00000000",
    dark: "#D07647",
    light: "#FAF3CE",
    states: {
      outlinedRestingBorder: "#E6AD76",
      outlinedHoverBackground: "#D07647",
      containedHoverBackground: "#FAF3CE",
    },
  },
  mute: {
    main: "#A3A9AF33",
    light: "#5FEFC033",
    dark: "#A3A9AF33",
    states: {
      containedHoverBackground: "#A3A9AF33",
    },
  },
  info: {
    main: "#5FEFC0",
    dark: "#5FEFC033",
    light: "#5FEFC0",
    states: {
      outlinedRestingBorder: "#5FEFC0",
      outlinedHoverBackground: "#5FEFC033",
      containedHoverBackground: "#5FEFC033",
    },
  },

  action: {
    hover: "#5FEFC0",
    active: "#5FEFC033",
    disabled: "#A3A9AF",
    disabledBackground: "#A3A9AF33",
    hoverOpacity: 0.5,
    selected: "#5FEFC0",
  },
  success: {
    main: "#06D7D7",
    dark: "#037777",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#D7263D",
    dark: "#68282F",
    light: "#FDA49A",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#FAEA5D",
    dark: "#817F48",
    light: "#FFEA91",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#5FEFC0",
    disabled: "#A3A9AF",
  },
  background: {
    default: "#020300",
    paper: "#020300",
  },
  divider: "#5FEFC0",
  other: {
    divider: "#D5D9DD",
    innerDivider: "#323234",
    border: "#D5D9DD",
    darkGreen80: "#3F8C72CC",
    darkCharcoal: "#333333",
    mainGradient: "linear-gradient(to right, #505DFF, #7CE1E0)",
    yellow: "#FFC90E",
  },
  grey: GREY_TONES,
}

const darkPalette: PaletteOptions | undefined = {
  mode: "dark",
  primary: {
    main: "#5FEFC0",
    dark: "#5FEFC033",
    light: "#6EAE99",
    states: {
      outlinedRestingBorder: "#5FEFC0",
      outlinedHoverBackground: "#5FEFC033",
      containedHoverBackground: "#6EAE99",
    },
  },
  secondary: {
    main: "#00000000",
    dark: "#D07647",
    light: "#FAF3CE",
    states: {
      outlinedRestingBorder: "#E6AD76",
      outlinedHoverBackground: "#D07647",
      containedHoverBackground: "#FAF3CE",
    },
  },
  mute: {
    main: "#A3A9AF33",
    light: "#5FEFC033",
    dark: "#A3A9AF33",
    states: {
      containedHoverBackground: "#A3A9AF33",
    },
  },
  info: {
    main: "#5FEFC0",
    dark: "#5FEFC033",
    light: "#5FEFC0",
    states: {
      outlinedRestingBorder: "#5FEFC0",
      outlinedHoverBackground: "#5FEFC033",
      containedHoverBackground: "#5FEFC033",
    },
  },

  action: {
    hover: "#5FEFC0",
    active: "#5FEFC033",
    disabled: "#A3A9AF",
    disabledBackground: "#A3A9AF33",
    hoverOpacity: 0.5,
    selected: "#5FEFC0",
  },
  success: {
    main: "#06D7D7",
    dark: "#037777",
    light: "#83EBEB",
    alert: {
      background: "#E6FFFF",
    },
  },
  error: {
    main: "#D7263D",
    dark: "#68282F",
    light: "#FDA49A",
    alert: {
      background: "#FEECEB",
    },
  },
  warning: {
    main: "#FAEA5D",
    dark: "#817F48",
    light: "#FFEA91",
    alert: {
      content: "#000000",
      background: "#FFF3C8",
    },
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#5FEFC0",
    disabled: "#A3A9AF",
  },
  background: {
    default: "#020300",
    paper: "#020300",
  },
  divider: "#5FEFC0",
  other: {
    divider: "#D5D9DD",
    innerDivider: "#323234",
    border: "#D5D9DD",
    darkGreen80: "#3F8C72CC",
    darkCharcoal: "#333333",
    mainGradient: "linear-gradient(to right, #505DFF, #7CE1E0)",
    yellow: "#FFC90E",
  },
  grey: GREY_TONES,
}
export default { lightPalette, darkPalette }
