import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { BLOCK_TIME } from "../constants"
import { BigNumber } from "@ethersproject/bignumber"
import { Zero } from "@ethersproject/constants"
import { getMinichefV2Erc1155UsersData } from "../utils/minichefV2Erc1155"
import { getMinichefV2Erc20UsersData } from "../utils/minichefV2Erc20"
import { getMinichefV2Erc721UsersData } from "../utils/minichefV2Erc721"
import { useActiveWeb3React } from "../hooks"
import usePoller from "../hooks/usePoller"

type FarmsRewards = { [farmName: string]: BigNumber }
type AggRewards = FarmsRewards & {
  totalNoah: BigNumber
  totalExternal: BigNumber
} & {
  ERC20totalNoah: BigNumber
  ERC20totalExternal: BigNumber
} & {
  ERC721totalNoah: BigNumber
  ERC721totalExternal: BigNumber
} & {
  ERC1155totalNoah: BigNumber
  ERC1155totalExternal: BigNumber
}

const emptyAggRewards = {
  totalNoah: Zero,
  totalExternal: Zero,
  ERC20totalNoah: Zero,
  ERC20totalExternal: Zero,
  ERC721totalNoah: Zero,
  ERC721totalExternal: Zero,
  ERC1155totalNoah: Zero,
  ERC1155totalExternal: Zero,
} as AggRewards

export const FarmRewardsBalancesContext = React.createContext<FarmsRewards>({
  totalNoah: Zero,
  totalExternal: Zero,
})

export default function FarmRewardsBalancesProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const [aggBalances, setAggBalances] = useState<AggRewards>(emptyAggRewards)
  // const { chainId } = useActiveWeb3React()
  // const poolsRewardsBalances = usePoolsRewardBalances()
  // const gaugeRewardBalance = useGaugeRewardTotal()
  // const gaugesAreActive = areGaugesActive(chainId)
  // const { vested: retroBalanceVested, total: retroBalanceTotal } =
  //   useRetroactiveRewardBalance()

  const rewardsBalances = useMinichefV2RewardsBalances()

  useMemo(() => {
    // const total = Object.values({
    //   ...poolsRewardsBalances,
    //   retroBalanceVested,
    //   ...(gaugesAreActive && { gaugeRewardBalance }),
    // }).reduce((acc, bal) => {
    //   return acc.add(bal || Zero)
    // }, Zero)

    const totalNoah = Zero
    const totalExternal = Zero

    setAggBalances({
      //   ...poolsRewardsBalances,
      //   retroactive: retroBalanceVested,
      //   retroactiveTotal: retroBalanceTotal,
      //   total,
      // ...rewardsBalances,
      totalNoah: totalNoah,
      totalExternal: totalExternal,
      ERC20totalNoah: rewardsBalances.ERC20NoahReward,
      ERC20totalExternal: rewardsBalances.ERC20ExternalReward,
      ERC721totalNoah: rewardsBalances.ERC721NoahReward,
      ERC721totalExternal: rewardsBalances.ERC721ExternalReward,
      ERC1155totalNoah: rewardsBalances.ERC1155NoahReward,
      ERC1155totalExternal: rewardsBalances.ERC1155ExternalReward,
    })
  }, [rewardsBalances])

  return (
    <FarmRewardsBalancesContext.Provider value={aggBalances}>
      {children}
    </FarmRewardsBalancesContext.Provider>
  )
}

function useMinichefV2RewardsBalances() {
  const { chainId, account, library } = useActiveWeb3React()
  const [balances, setBalances] = useState<{
    ERC20NoahReward: BigNumber
    ERC20ExternalReward: BigNumber
    ERC721NoahReward: BigNumber
    ERC721ExternalReward: BigNumber
    ERC1155NoahReward: BigNumber
    ERC1155ExternalReward: BigNumber
  }>({
    ERC20NoahReward: Zero,
    ERC20ExternalReward: Zero,
    ERC721NoahReward: Zero,
    ERC721ExternalReward: Zero,
    ERC1155NoahReward: Zero,
    ERC1155ExternalReward: Zero,
  })

  const fetchBalance = useCallback(async () => {
    try {
      if (!library || !chainId || !account) return

      const [erc20UserInfo, erc721UserInfo, erc1155UserInfo] =
        await Promise.all([
          getMinichefV2Erc20UsersData(library, chainId, account),
          getMinichefV2Erc721UsersData(library, chainId, account),
          getMinichefV2Erc1155UsersData(library, chainId, account),
        ])

      // ERC20 Noah is 0, ERC20 External is 1
      const totalErc20Rewards = Object.values({ ...erc20UserInfo }).reduce(
        (acc, { pendingNoah, pendingToken }) => {
          return [
            acc[0].add(pendingNoah || Zero),
            acc[1].add(pendingToken?.rewardAmount || Zero),
          ]
        },
        [Zero, Zero],
      )

      // ERC721 Noah is 0, ERC721 External is 1
      const totalErc721Rewards = Object.values({ ...erc721UserInfo }).reduce(
        (acc, { pendingNoah, pendingToken }) => {
          return [
            acc[0].add(pendingNoah || Zero),
            acc[1].add(pendingToken?.rewardAmount || Zero),
          ]
        },
        [Zero, Zero],
      )

      // ERC1155 Noah is 0, ERC1155 External is 1
      const totalErc1155Rewards = Object.values({ ...erc1155UserInfo }).reduce(
        (acc, { pendingNoah, pendingToken }) => {
          return [
            acc[0].add(pendingNoah || Zero),
            acc[1].add(pendingToken?.rewardAmount || Zero),
          ]
        },
        [Zero, Zero],
      )

      setBalances({
        ERC20NoahReward: totalErc20Rewards[0],
        ERC20ExternalReward: totalErc20Rewards[1],
        ERC721NoahReward: totalErc721Rewards[0],
        ERC721ExternalReward: totalErc721Rewards[1],
        ERC1155NoahReward: totalErc1155Rewards[0],
        ERC1155ExternalReward: totalErc1155Rewards[1],
      })
    } catch (e) {
      console.error("Unable to get farms rewards balances: ", e)
      setBalances({
        ERC20NoahReward: Zero,
        ERC20ExternalReward: Zero,
        ERC721NoahReward: Zero,
        ERC721ExternalReward: Zero,
        ERC1155NoahReward: Zero,
        ERC1155ExternalReward: Zero,
      })
    }
  }, [account, chainId, library])

  useEffect(() => {
    void fetchBalance()
  }, [fetchBalance])

  usePoller(() => void fetchBalance(), BLOCK_TIME)

  return balances
}

// function useGaugeRewardTotal() {
//   const userState = useContext(UserStateContext)

//   return useMemo(() => {
//     if (!userState) {
//       return Zero
//     }

//     const totalSdlFromGauges = Object.values(
//       userState?.gaugeRewards ?? {},
//     ).reduce((sum, { claimableSDL }) => sum.add(claimableSDL), Zero)

//     return totalSdlFromGauges
//   }, [userState])
// }
