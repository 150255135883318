import {
  AERO_FARM_NAME,
  AERO_TOKEN,
  ARB_FARM_NAME,
  ARB_TOKEN,
  BRETT_FARM_NAME,
  BRETT_TOKEN,
  CBETH_FARM_NAME,
  CBETH_TOKEN,
  ChainId,
  DEGEN_FARM_NAME,
  DEGEN_TOKEN,
  FarmName,
  FarmType,
  MINICHEF_V2_ERC1155_POOL_CONTRACT_ADDRESSES,
  MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
  MINICHEF_V2_ERC721_POOL_CONTRACT_ADDRESSES,
  NOAH_FARM_NAME,
  NOAH_KEY_TOKEN,
  NOAH_MASTER_KEY_FARM_NAME,
  NOAH_PLAYER_TOKEN,
  NOAH_TOKEN,
  NOAH_TOWER_PLAYER_FARM_NAME,
  NOAH_WETH_LP_FARM_NAME,
  NOAH_WETH_LP_TOKEN,
  NORMIE_FARM_NAME,
  NORMIE_TOKEN,
  Token,
  USDBC_FARM_NAME,
  USDBC_TOKEN,
  USDC_FARM_NAME,
  USDC_TOKEN,
  WBTC_TOKEN,
  WETH_TOKEN,
  WRAPPED_BTC_FARM_NAME,
  WRAPPED_ETH_FARM_NAME,
  buildPids,
} from "."
import { MinichefV2PoolInfo } from "../interfaces/minichefV2"

export type FarmConfigs = {
  icon?: string
  name: FarmName
  token: Token
  addresses: { [chainId in ChainId]: string }
  minichefV2Pids: { [chainid in ChainId]: number | null }
  typeAsset: FarmType
  isLP?: boolean
  isPlatformNativeToken?: boolean
  isEnded?: boolean
}

export type FarmsMap = {
  [farmName: string]: FarmConfigs
}

export type BasicFarmInfo = {
  name: FarmName
  address: string
  rewardToken: string
}

export function getMinichefV2Pid(
  chainId: ChainId,
  farmName: FarmName,
): number | null {
  return FARMS_MAP?.[farmName].minichefV2Pids?.[chainId] || null
}

export function getFarmsWithPids(
  chainId: ChainId,
  farms: BasicFarmInfo[],
): MinichefV2PoolInfo[] {
  return farms.map(({ address, name }) => {
    return {
      pid: getMinichefV2Pid(chainId, name),
      farmAddress: address,
      farmName: name,
    } as MinichefV2PoolInfo
  })
}

/** FARM SETTINGS */
export const FARMS_MAP: FarmsMap = {
  [NOAH_MASTER_KEY_FARM_NAME]: {
    name: NOAH_MASTER_KEY_FARM_NAME,
    token: NOAH_KEY_TOKEN,
    addresses: MINICHEF_V2_ERC1155_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC1155,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 1,
      [ChainId.PAC]: 1,
      [ChainId.BLAST_SEPOLIA]: 1,
      [ChainId.BASE_SEPOLIA]: 1,
      [ChainId.BASE_MAINNET]: 1,
    }),
  },
  [NOAH_TOWER_PLAYER_FARM_NAME]: {
    name: NOAH_TOWER_PLAYER_FARM_NAME,
    token: NOAH_PLAYER_TOKEN,
    addresses: MINICHEF_V2_ERC721_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC721,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 1,
      [ChainId.PAC]: 1,
      [ChainId.BLAST_SEPOLIA]: 1,
      [ChainId.BASE_SEPOLIA]: 1,
      [ChainId.BASE_MAINNET]: 1,
    }),
  },
  [NOAH_WETH_LP_FARM_NAME]: {
    name: NOAH_WETH_LP_FARM_NAME,
    token: NOAH_WETH_LP_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: true,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 6,
      [ChainId.PAC]: 6,
      [ChainId.BLAST_SEPOLIA]: 6,
      [ChainId.BASE_SEPOLIA]: 6,
      [ChainId.BASE_MAINNET]: 6,
    }),
  },
  [NOAH_FARM_NAME]: {
    name: NOAH_FARM_NAME,
    token: NOAH_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isPlatformNativeToken: true,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 1,
      [ChainId.PAC]: 1,
      [ChainId.BLAST_SEPOLIA]: 1,
      [ChainId.BASE_SEPOLIA]: 1,
      [ChainId.BASE_MAINNET]: 1,
    }),
  },
  [WRAPPED_ETH_FARM_NAME]: {
    name: WRAPPED_ETH_FARM_NAME,
    token: WETH_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 2,
      [ChainId.PAC]: 2,
      [ChainId.BLAST_SEPOLIA]: 2,
      [ChainId.BASE_SEPOLIA]: 2,
      [ChainId.BASE_MAINNET]: 2,
    }),
  },
  [WRAPPED_BTC_FARM_NAME]: {
    name: WRAPPED_BTC_FARM_NAME,
    token: WBTC_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 3,
      [ChainId.PAC]: 3,
      [ChainId.BLAST_SEPOLIA]: 3,
      [ChainId.BASE_SEPOLIA]: 3,
      [ChainId.BASE_MAINNET]: 3,
    }),
  },
  [USDC_FARM_NAME]: {
    name: USDC_FARM_NAME,
    token: USDC_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 4,
      [ChainId.PAC]: 4,
      [ChainId.BLAST_SEPOLIA]: 4,
      [ChainId.BASE_SEPOLIA]: 4,
      [ChainId.BASE_MAINNET]: 4,
    }),
  },
  [ARB_FARM_NAME]: {
    name: ARB_FARM_NAME,
    token: ARB_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 5,
      [ChainId.PAC]: 5,
      [ChainId.BLAST_SEPOLIA]: 5,
      [ChainId.BASE_SEPOLIA]: 5,
      [ChainId.BASE_MAINNET]: 5,
    }),
  },
  [AERO_FARM_NAME]: {
    name: AERO_FARM_NAME,
    token: AERO_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
  [BRETT_FARM_NAME]: {
    name: BRETT_FARM_NAME,
    token: BRETT_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
  [CBETH_FARM_NAME]: {
    name: CBETH_FARM_NAME,
    token: CBETH_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
  [DEGEN_FARM_NAME]: {
    name: DEGEN_FARM_NAME,
    token: DEGEN_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
  [NORMIE_FARM_NAME]: {
    name: NORMIE_FARM_NAME,
    token: NORMIE_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
  [USDBC_FARM_NAME]: {
    name: USDBC_FARM_NAME,
    token: USDBC_TOKEN,
    addresses: MINICHEF_V2_ERC20_POOL_CONTRACT_ADDRESSES,
    typeAsset: FarmType.ERC20,
    isLP: false,
    isEnded: false,
    minichefV2Pids: buildPids({
      [ChainId.HARDHAT]: 0,
      [ChainId.PAC]: 0,
      [ChainId.BLAST_SEPOLIA]: 0,
      [ChainId.BASE_SEPOLIA]: 0,
      [ChainId.BASE_MAINNET]: 0,
    }),
  },
}
/** FARM SETTINGS END */
